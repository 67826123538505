import React, { useEffect, useState } from "react";
import "./styles.scss";
import InfoCard from "../../../atoms/InfoCard";
import Charts from "./Charts";
// import Business from "../../../icons/Business";
// import Payment from "../../../icons/Payment";
// import Email from "../../../icons/Email";
// import Message from "../../../icons/Message";
import { Heading } from "../../../atoms/Heading";
import EyeIcon from "../../../icons/eye.icon";
import Counterparty from "../../../icons/Counterparty";
import { useCookies } from "react-cookie";
import { getData } from "../../../../Services";
import Broker from "../../../icons/Broker";
import Investor from "../../../icons/Investor";
import Payments from "../../../icons/Payments";
import Notes from "../../../icons/Notes";
import VisitorChartContainer from "./InvoiceChart";
import ClientTable from "./Tables/client";
import EmployeesTable from "./Tables";
import IconCircle from "../../../atoms/IconCircle";
import { useNavigate } from "react-router-dom";
import Funding from "../../../icons/Funding";
import Reports from "../../../icons/reports";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";
import ArrowDown from "../../../icons/ArrowDown";
import MyDropdown from "../../../atoms/MyDropdown";
import { currencyData, currencySymbol } from "../../../../helpers/currencyData";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
  set,
  subDays,
  endOfDay,
} from "date-fns";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const Home = () => {
  const [cookies] = useCookies(["t", "bid"]);
  const [businessData, setBusinessData] = useState();
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const is_client_user =
    (accessData &&
      (accessData?.["Client Invoices"]?.["view"] ||
        accessData?.["Client Invoices"]?.["delete"] ||
        accessData?.["Client Invoices"]?.["export"] ||
        accessData?.["Client Invoices"]?.["add/edit"])) ||
    cookies?.iso;
  const is_emp_user =
    (accessData &&
      (accessData?.["Employee Invoices"]?.["view"] ||
        accessData?.["Employee Invoices"]?.["delete"] ||
        accessData?.["Employee Invoices"]?.["export"] ||
        accessData?.["Employee Invoices"]?.["add/edit"])) ||
    cookies?.iso;
  const defaultToday = new Date();
  const [date, setDate] = useState({
    start_date: subDays(new Date(), 30),
    end_date: endOfDay(new Date()),
  });
  const [modeVale, setModeValue] = useState(
    is_client_user ? "Client" : "Employee"
  );
  const [date2, setDate2] = useState({
    start_date: subDays(new Date(), 30),
    end_date: endOfDay(new Date()),
  });
  // const [date2, setDate2] = useState([null, null]);
  const [currency, setCurrency] = useState("USD");
  const options =
    is_emp_user && is_client_user
      ? ["Client", "Employee"]
      : is_emp_user
      ? ["Employee"]
      : ["Client"];
  const [clientList, setClientList] = useState();
  const [clientuser, setClientUserList] = useState();
  const [employeeList, setEmployeeList] = useState();
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const getbusinessData = async () => {
    const start_date = new Date(date2?.start_date);
    const end_date = new Date(date2?.end_date);

    // const finalStart = new Date(
    //   start_date.getTime() - start_date.getTimezoneOffset() * 60000
    // ).toISOString();

    // const finalEnd = new Date(
    //   end_date.getTime() - end_date.getTimezoneOffset() * 60000
    // ).toISOString();
    const finalStart = new Date(
      Date.UTC(
        start_date.getUTCFullYear(),
        start_date.getUTCMonth(),
        start_date.getUTCDate(),
        0, // 00:00:00 UTC
        0,
        0,
        0
      )
    ).toISOString();

    const finalEnd = new Date(
      Date.UTC(
        end_date.getUTCFullYear(),
        end_date.getUTCMonth(),
        end_date.getUTCDate(),
        23, // 23:59:59.999 UTC
        59,
        59,
        999
      )
    ).toISOString();
    try {
      const res = await getData({
        endpoint: "businessDashboard/getBusinessStats",
        params: {
          business_id: cookies.bid,
          group_id: cookies.group_id ? cookies.group_id : "",
          currency: currency ? currency.toLowerCase() : "usd",
          start_date: finalStart,
          end_date: finalEnd,
          user_type:
            is_emp_user && is_client_user
              ? "Both"
              : is_emp_user
              ? "Employee"
              : "Client",
        },
        token: cookies.t,
      });
      setBusinessData(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getClientsList = async () => {
    const res = await getData({
      endpoint: "cusers/getClientsByEmployees",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    setClientList(res);
  };

  const getClientsUsersList = async () => {
    const res = await getData({
      endpoint: "cusers/getEmployeeList",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    setClientUserList(res);
  };

  const getEmployeeList = async () => {
    const res = await getData({
      endpoint: "employee/getEmployeeList",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
      },
    });
    setEmployeeList(res);
  };

  useEffect(() => {
    if (is_client_user || is_emp_user) {
      getbusinessData();
    }
    if (is_client_user) {
      getClientsList();
      getClientsUsersList();
    }
    if (is_emp_user) {
      getEmployeeList();
    }
  }, [currency, date2]);

  const filterData = {
    ...(is_client_user
      ? { Clients: clientList, ["Client Users"]: clientuser }
      : {}),
    ...(is_emp_user
      ? {
          ["Employee Users"]: employeeList,
        }
      : {}),
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Heading className="HeadingStyle"> Dashboard</Heading>
        {(is_emp_user || is_client_user) && (
          <div className="d-flex gap-2 aligin-items-center">
            {/* <MyDropdown
            // placeholder={"Select Currency"}
            value={"USD"}
            width="10vw"
            selectedValue={currency}
            data={currencyData}
            onChange={(option) => setCurrency(option)}
          /> */}
            <div className="dropdown dropdown2">
              <div
                className="dropdown-box dropdown2-box"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {currency}
                {/* <i className="ms-1 d-flex"> */}
                <ArrowDown />
                {/* </i> */}
              </div>

              <ul className="dropdown-menu dropdown2-menu">
                {currencyData?.map((option) => (
                  <li key={option.value}>
                    <button
                      className="dropdown-item dropdown-item2"
                      onClick={() => setCurrency(option.value)}
                    >
                      <span>{option.value}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <CustomDateRangePicker date={date2} setDate={setDate2} />
          </div>
        )}
      </div>
      <div className="dashboard-container">
        <div className="widgets">
          {((accessData &&
            (accessData?.["Employee Users"]?.["view"] ||
              accessData?.["Employee Users"]?.["delete"] ||
              accessData?.["Employee Users"]?.["export"] ||
              accessData?.["Employee Users"]?.["add/edit"])) ||
            cookies?.iso) && (
            <InfoCard
              data={businessData?.employees || 0}
              title="Total Employee users"
              icon={<Broker color="black" iconSize={"18px"} />}
              // color={"#efefef"}
              color="#FFD88D"
              iconSize={"18px"}
            />
          )}
          {((accessData &&
            (accessData?.["Client Users"]?.["view"] ||
              accessData?.["Client Users"]?.["delete"] ||
              accessData?.["Client Users"]?.["export"] ||
              accessData?.["Client Users"]?.["add/edit"])) ||
            cookies?.iso) && (
            <InfoCard
              // data={convertAndAddCurrencies(
              //   widgetData?.totalFundedData,
              //   currencyConversion
              // )}
              data={businessData?.clients || 0}
              title="Total Client Users"
              icon={<Investor color="black" />}
              color="#B1E5FC"
              iconSize={"18px"}
            />
          )}

          {(is_emp_user || is_client_user) && (
            <InfoCard
              // data={convertAndAddCurrencies(
              //   widgetData?.totalInterestData,
              //   currencyConversion
              // )}
              data={businessData?.bills || 0}
              title="Total Invoices"
              icon={<Broker color="black" iconSize={"10px"} />}
              color={"#efefef"}
              iconSize={"18px"}
            />
          )}
          {is_emp_user && (
            <InfoCard
              data={businessData?.employeeBillexpense?.toFixed(2) || 0}
              title="Employee Expenses"
              icon={<Payments color="black" />}
              // color={"#FFD88D"}
              color="#B1E5FC"
              iconSize={"18px"}
              unit={currencySymbol[currency]}
            />
          )}
          {is_client_user && (
            <InfoCard
              // data={convertAndAddCurrencies(
              //   widgetData?.totalInvestedData,
              //   currencyConversion
              // )}
              title="Client Expenses"
              data={businessData?.clientBillExpense?.toFixed(2) || 0}
              icon={<Notes color="black" />}
              color="#FFD88D"
              iconSize={"18px"}
              unit={currencySymbol[currency]}
            />
          )}
        </div>
        <div className="main">
          <div className="left">
            {(is_emp_user || is_client_user) && (
              <div className="common">
                <div className="mt-2">
                  <VisitorChartContainer
                    currency={currency}
                    is_emp_user={is_emp_user}
                    is_client_user={is_client_user}
                  />
                </div>
              </div>
            )}
            {(is_client_user || is_emp_user) && (
              <div className="common">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <Heading className={"HeadingSubStyle"}>
                    {modeVale === "Client"
                      ? "Client Summary"
                      : "Employee Summary"}
                  </Heading>
                  <div className="dropdown dropdown2">
                    <div
                      className="dropdown-box dropdown2-box"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {modeVale}
                      {/* <i className="ms-1 d-flex"> */}
                      <ArrowDown />
                      {/* </i> */}
                    </div>

                    <ul className="dropdown-menu dropdown2-menu">
                      {options?.map((option) => (
                        <li key={option}>
                          <button
                            className="dropdown-item dropdown-item2"
                            onClick={() => setModeValue(option)}
                          >
                            <span>{option}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="mt-2">
                  {/* <ClientTable /> */}
                  {modeVale === "Client" ? (
                    is_client_user ? (
                      <ClientTable />
                    ) : (
                      <></>
                    )
                  ) : is_emp_user ? (
                    <EmployeesTable />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="right">
            {(is_emp_user || is_client_user) && (
              <div className="common">
                <Heading className={"HeadingSubStyle"}>Quick Links</Heading>
                <div className="quick-links">
                  {is_client_user &&
                    accessData?.["Client Invoices"]?.["add/edit"] && (
                      <div
                        className="quick-link"
                        onClick={() => navigate("/dashboard/clientInvoice/add")}
                      >
                        <IconCircle
                          icon={<Funding />}
                          color="#FFD88D"
                          iconSize={"18px"}
                          size="40px"
                        />
                        <div className="description">New Client Invoice</div>
                      </div>
                    )}
                  {is_client_user && (
                    <div
                      className="quick-link"
                      onClick={() => navigate("/dashboard/clientInvoice")}
                    >
                      <IconCircle
                        icon={<Reports />}
                        color="#B1E5FC"
                        iconSize={"18px"}
                        size="40px"
                      />
                      <div className="description">Client Invoices</div>
                    </div>
                  )}
                  {is_emp_user && (
                    <div
                      className="quick-link"
                      onClick={() => navigate("/dashboard/employeeInvoice")}
                    >
                      <IconCircle
                        icon={<Notes />}
                        color={"#efefef"}
                        iconSize={"18px"}
                        size="40px"
                      />
                      <div className="description">Employee Invoices</div>
                    </div>
                  )}
                  {accessData &&
                    (accessData?.["Client Users"]?.["view"] ||
                      accessData?.["Client Users"]?.["delete"] ||
                      accessData?.["Client Users"]?.["export"] ||
                      accessData?.["Client Users"]?.["add/edit"]) && (
                      <div
                        className="quick-link"
                        onClick={() => navigate("/dashboard/clients")}
                      >
                        <IconCircle
                          icon={<Reports />}
                          color="#B1E5FC"
                          iconSize={"18px"}
                          size="40px"
                        />
                        <div className="description">Client Users</div>
                      </div>
                    )}
                  {accessData &&
                    (accessData?.["Employee Users"]?.["view"] ||
                      accessData?.["Employee Users"]?.["delete"] ||
                      accessData?.["Employee Users"]?.["export"] ||
                      accessData?.["Employee Users"]?.["add/edit"]) && (
                      <div
                        className="quick-link"
                        onClick={() => navigate("/dashboard/employees")}
                      >
                        <IconCircle
                          icon={<Notes />}
                          color="#FFD88D"
                          iconSize={"18px"}
                          size="40px"
                        />
                        <div className="description">Employee Users</div>
                      </div>
                    )}
                </div>
              </div>
            )}
            {(is_emp_user || is_client_user) && (
              <div className="common">
                <div className="d-flex align-items-center justify-content-between drop-items-hone-tab">
                  <div className="date-picker-bar-chart-left">
                    <FilterComponent
                      filterData={filterData}
                      selectedFilters={filters}
                      onFilterChange={(filter) => {
                        setFilters(filter);
                      }}
                      // className={"date-picker-bar-chart"}
                      mode="Invoice"
                      unit={currencySymbol[currency]}
                    />
                  </div>
                  <span>
                    <CustomDateRangePicker
                      setDate={setDate}
                      date={date}
                      className={"date-picker-bar-chart-right"}
                    />
                  </span>
                </div>
                {is_client_user && (
                  <div className="common">
                    <Charts
                      title={"Client Exposure"}
                      type="Client"
                      date={date}
                      setDate={setDate}
                      filters={filters}
                      currency={currency}
                    />
                  </div>
                )}
                {is_emp_user && (
                  <div className="common mt-3">
                    <Charts
                      title={"Employee Exposure"}
                      type="Employee"
                      date={date}
                      setDate={setDate}
                      filters={filters}
                      currency={currency}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
