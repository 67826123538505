import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../Services";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import NormalModal from "../../../atoms/modals/Modal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import FilterComponent from "../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../icons/eye.icon";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import KeyIcon from "../../../icons/key";

const Clients = () => {
  const navigate = useNavigate();
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Client Users"];
  const [cookies, setCookie] = useCookies([
    "t",
    "bid",
    "ibu",
    "mode",
    "iso",
    "group_id",
  ]);
  const isOwner = cookies.iso;
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateView, setModalStateView] = useState({
    modalId: "",
    modalTitle: "",
    data: null,
    isModalOpen: false,
  });
  const [clientData, setClientData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const [resendLoader, setResendLoader] = useState(false);

  const menu = [
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        setModalStateView({
          isModalOpen: true,
          modalTitle: selectedRow.name,
          modalId: selectedRow._id,
          data: selectedRow,
        });
        setAnchorEl(null);
      },
    },
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          modalTitle: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
    ...(selectedRow && selectedRow?.status === "Pending"
      ? [
          {
            icon: <KeyIcon height="20" width="20" />,
            label: (
              <div className="d-flex align-items-center gap-1">
                Resend Invite
                {resendLoader && (
                  <span
                    className="spinner-border spinner-border-lr ms-2"
                    style={{ color: "#3b424a", width: "20px", height: "20px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </div>
            ),
            onClick: () => {
              handleResend(selectedRow.email);
            },
          },
        ]
      : []),
  ];

  const handleResend = async (newVal) => {
    setResendLoader(true);
    const response = await getData({
      endpoint: "cusers/resendInvite",
      params: { email: newVal },
      token: cookies.t,
    });
    if (response && response.status) {
      toast.success("Invite Resended Sucessfully", {
        style: confimationStyles,
        duration: 1000,
      });
      setAnchorEl(null);
    } else {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    }
    setResendLoader(false);
  };

  const GetemployeeData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "cusers",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          type: "Client",
          business_id: cookies?.bid,
          status: filters?.["Status"],
          client_names: filters?.["Client Name"],
          group_id: cookies.group_id ? cookies.group_id : "",
        },
      });
      setEmployeeData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetemployeeData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  useEffect(() => {
    const getClientsList = async () => {
      const res = await getData({
        endpoint: "client/getClientList",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
        },
      });
      setClientData(res);
    };
    getClientsList();
  }, []);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "clientData.name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <span>{params.row.clientData.name}</span>;
      },
    },
    {
      field: "name",
      headerName: "Client User",
      flex: 1,
      sortable: true,
    },
    {
      field: "primary_phone",
      headerName: "Primary Phone No.",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.primary_number && params.row.primary_cc}{" "}
            {params.row.primary_number}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      sortable: true,
      renderCell: (params) => {
        return <div className={`no_capitalize`}>{params?.row?.email}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status === "Pending" ? (
              <span className="text-warning fw-bold">Pending</span>
            ) : params?.row.status === "Active" ? (
              <span className="text-success fw-bold">Active</span>
            ) : (
              <span className="text-error fw-bold">Inactive</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              // items={menu}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (
      (roleData &&
        roleData?.["add/edit"] &&
        roleData?.view &&
        roleData?.delete) ||
      isOwner
    ) {
      return menu;
    } else if (roleData && roleData?.["add/edit"] && roleData?.view) {
      return [menu[0], menu[1]];
    } else if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return [menu[1], menu[2]];
    } else if (roleData && roleData?.view && roleData?.delete) {
      return [menu[0], menu[2]];
    } else if (roleData && roleData?.view) {
      return [menu[0]];
    } else if (roleData && roleData?.delete) {
      return [menu[2]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[1]];
    } else return [];
  };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!(searchData || Object.keys(filters).length > 0) ? (
          <>
            <NoContentCard
              title="No Client Users Found"
              tag="Create Bill"
              type="Pricing"
            />
          </>
        ) : (
          <>
            <NoContentCard
              title="No Client Users created yet!"
              tag="Create Client User"
              type="Pricing"
              classes="d-flex align-items-center"
              {...((roleData && roleData?.["add/edit"]) || isOwner
                ? {
                    handleClick: () => {
                      navigate("/dashboard/clients/add");
                    },
                    subtitle:
                      "Currently no client user created yet click on a create client user button to create client user",
                  }
                : {})}
            />
          </>
        )}
      </>
    );
  });

  const handleCloseView = () => {
    setModalStateView(() => ({
      isModalOpen: false,
      data: null,
      modalId: "",
      modalTitle: "",
    }));
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };

  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "cusers/delete",
        token: cookies.t,
        params: {
          id: modalStateDelete?.modalId,
          roleGroupId: cookies.group_id ? cookies.group_id : "",
        },
      });
      if (res) {
        toast.success("Client User Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetemployeeData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" },
      { label: "Pending", value: "Pending" },
    ],
    "Client Name": clientData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "cusers/exportCusers",
      token: cookies.t,
      fileName: "Client Users",
      params: {
        search: searchData,
        type: "Client",
        business_id: cookies?.bid,
        status: filters?.["Status"],
        client_names: filters?.["Client Name"],
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });

    if (res) {
      toast.success("Client Users Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Client Users</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />
          {((roleData && roleData?.export) || isOwner) && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}

          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
            // selectedFilters={filters}
          />
          {((roleData && roleData?.["add/edit"]) || isOwner) && (
            <CustomButton
              text="Create Client User"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={employeeData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        loader={loader}
      />
      {employeeData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={employeeData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Client User"
        name={modalStateDelete.modalTitle}
        // description="Are you sure do you want delete client user from the Client User list."
      />
      <NormalModal
        isActive={modalStateView.isModalOpen}
        onClose={handleCloseView}
        title={
          modalStateView.modalTitle && `${modalStateView.modalTitle}  Details`
        }
        width="30vw"
      >
        <div className="w-100 d-flex flex-column mb-3">
          <LabelDisplay
            label="Client Name"
            value={modalStateView?.data?.clientData?.name}
          />
          <LabelDisplay label="Name" value={modalStateView?.data?.name} />
          <LabelDisplay
            label="Primary Phone Number"
            value={`${modalStateView?.data?.primary_cc} ${modalStateView?.data?.primary_number}`}
          />
          <LabelDisplay label="Email" value={modalStateView?.data?.email} />
          <LabelDisplay
            label="Designation"
            value={modalStateView?.data?.designation}
          />
          <LabelDisplay label="Status" value={modalStateView?.data?.status} />
          <LabelDisplay
            label="Created On"
            value={moment(modalStateView?.data?.createdAt).format("YYYY-MM-DD")}
          />
          <LabelDisplay
            label="Updated On"
            value={moment(modalStateView?.data?.updatedAt).format("YYYY-MM-DD")}
          />
        </div>
      </NormalModal>
    </>
  );
};

const LabelDisplay = ({ label, value, className, modalStyles = false }) => {
  return (
    <div className={`d-flex ${className}`}>
      {label && (
        <label className={style.label_view} style={{ width: "40%" }}>
          {label}
        </label>
      )}
      <div className={style.label_description} style={{ width: "48%" }}>
        {value ? value : "--"}
      </div>
    </div>
  );
};
export default Clients;
