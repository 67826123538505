import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import MultipleUserSelectComponent from "../../../../atoms/TagMultiSelect";
import { useSelector } from "react-redux";

const UserForm = ({
  onClose,
  initialValues,
  id,
  refetchData,
  setEditData,
  selectedActivityIds,
  setSelectedActivityIds,
}) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [clientData, setClientData] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const initialData = {
    name: initialValues?.name || "",
  };

  const getRolesData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "cusers/getAllEmployeeList",
        params: {
          business_id: cookies.bid,
        },
        token: cookies.t,
      });

      if (res) {
        const transformedData = res.map((item) => ({
          emp_id: item.value,
          name: item.label,
          tag: item?.tag,
        }));
        setClientData(transformedData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    const fetchRolesAndSetIds = async () => {
      await getRolesData();
      if (initialValues?.roleInfo) {
        const ids = initialValues.roleInfo.map((role) => role._id);
        setSelectedActivityIds(ids);
      }
    };

    fetchRolesAndSetIds();
  }, [getRolesData, initialValues]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const res = await postData({
      endpoint: "rolegroupBusiness/create",
      token: cookies.t,
      data: {
        name: values.name,
        group_ids: selectedActivityIds,
        business_id: cookies.bid,
      },
    });
    if (res?.data?.statusCode && res?.data) {
      setSelectedActivityIds([]);
      toast.success("Role Group Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      // await setFieldValue("client_id", "");
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values, { resetForm }) => {
    try {
      const res = await patchData({
        endpoint: "rolegroupBusiness/update",
        token: cookies.t,
        params: {
          id: id,
        },
        data: {
          name: values.name,
          group_ids: selectedActivityIds,
          business_id: cookies.bid,
        },
      });

      if (res.data.status) {
        refetchData();
        setSelectedActivityIds([]);
        toast.success("Role Group Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  const handleSelectedActivityName = useCallback((selectedItems) => {
    setSelectedActivityIds(selectedItems);
  }, []);

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "rolegroupBusiness/checkRoleGroupName",
      token: cookies.t,
      params: { name: newVal, business_id: cookies.bid },
    });
    if (response.status && response.data) {
      setEmailError("Rolegroup Name Already Exists");
    } else {
      setEmailError(null);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={initialValues ? handleEdit : handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => (
        <Form>
          <div className="row mt-3">
            {/* <div className="col-3">
              <TagDropdown
                placeholder="Client Name"
                data={clientData}
                onChange={(option) => {
                  setFieldValue("client_id", option?.value);
                  setFieldValue("client_name", option?.label);
                }}
                handleBlur={(e) => {
                  setFieldTouched("client_id", true);
                  handleBlur(e);
                }}
                selectedValue={values.client_id}
                format="all"
                menuOpen={true}
                name="client_id"
                touched={touched.client_id}
                errors={errors.client_id}
                width="32.426%"
              />
            </div> */}
            <div className="col-md  ">
              <MultipleUserSelectComponent
                title={"Select Clients"}
                data={clientData}
                onSelect={handleSelectedActivityName}
                selectAllLabel="All"
                selectedEmployeeIds={selectedActivityIds}
              />
                       
            </div>
            <div className="col-3">
              {/* <MyInput
                type="text"
                id="name"
                name="name"
                // totalWidth={true}
                customholder="Enter Group Name"
                customBlur={(e) => {
                  if (initialValues.name !== values.name) {
                    checkEmail(values.name);
                  }
                }}
                blurError={emailError}
              /> */}

              <MyInput
                type="text"
                id="name"
                name="name"
                customholder="Enter Group Name"
                width="100%"
                customBlur={(e) => {
                  if (initialValues?.name !== values.name) {
                    checkEmail(values.name);
                  }
                }}
                blurError={emailError}
                required
              />
            </div>
            <div className="col-3"></div>

            {((roleData && roleData?.["add/edit"]) || isOwner) && (
              <div className="col-3">
                {initialValues ? (
                  <button
                    type="submit"
                    className="btn-smlg btn-primary w-50"
                    style={{ marginLeft: "45%" }}
                    disabled={!isValid || isSubmitting || emailError}
                  >
                    Update
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                ) : (
                  <div className="d-flex gap-4">
                    <button
                      type="button"
                      className="btn-smlg btn-outline-primary w-25 flex-1"
                      disabled={isSubmitting || emailError}
                      onClick={() => {
                        setSelectedActivityIds([]);
                        resetForm();
                      }}
                    >
                      Reset
                    </button>

                    <button
                      type="submit"
                      className="btn-smlg btn-primary w-25 flex-1"
                      disabled={isSubmitting}
                    >
                      Add
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
