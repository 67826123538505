import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
// import Breadcrumb from "../../../../molecules/Breadcrumb";
import { Heading } from "../../../../atoms/Heading";
import { useLocation, useParams } from "react-router-dom";
// import { isDraft } from "@reduxjs/toolkit";
import UserManage from "./index";
import RoleMangement from "../RoleManagement";
import RoleGroupMangement from "../RoleGroup";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
// import { useSelector } from "react-redux";

const UserManagement = () => {
  const location = useLocation();
  const state = location.state;
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  const [activeTab, setActiveTab] = useState("Client");
  let accessData = useSelector((state) => state?.accessData?.accessData);

  const { id, isDraft } = useParams();

  console.log("State Camed Is :", state);

  useEffect(() => {
    if (state && state.customState) {
      setActiveTab(state.customState);
    } else setActiveTab("Role Management");
  }, []);

  const data = useMemo(() => [
    ...((accessData && accessData?.["User Management"]) || isOwner
      ? [
          {
            id: 2,
            label: "Role Management",
            view: <RoleMangement />,
          },
        ]
      : []),
    ...((accessData && accessData?.["User Management"]) || isOwner
      ? [
          {
            id: 3,
            label: "User Management",
            view: <UserManage />,
          },
        ]
      : []),
    ...((accessData && accessData?.["User Management"]) || isOwner
      ? [
          {
            id: 1,
            label: "Client Group Management",
            view: <RoleGroupMangement />,
          },
        ]
      : []),
  ]);

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };

  return (
    <>
      {/* <Breadcrumb
        labels={[
          "Counterparties",
          `${
            id
              ? isDraft
                ? "Edit Draft Counterparty"
                : "Edit Counterparty"
              : "Add Counterparty"
          }`,
        ]}
        stepsToRemove={id && isDraft ? 2 : 1}
      /> */}
      <Heading className="addHeaderStyle">{activeTab} </Heading>

      <Tabstrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={data}
        allowedTabId={location?.state?.edit ? handleAllowId : ""}
        areTabsDisabled={location?.state?.edit ? true : false}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default UserManagement;
