import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../../atoms/CustomButton";
import PermissionSelector from "./PermissionSelector";
import { useNavigate, useParams } from "react-router-dom";
import { postData, getData, patchData } from "../../../../../../Services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";
import { useCookies } from "react-cookie";
import MyInput from "../../../../../atoms/MyInput";
import Breadcrumb from "../../../../../atoms/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";
import { useSelector } from "react-redux";
import MinusIcon from "../../../../../icons/minusIcon";
import { capitalizeLetter } from "../../../../../../helpers/capatalizeLetters";

const AddRole = ({ type = "add" }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "roleBusiness/getRoleByID",
        token: cookies.t,
        params: { id: id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const modules = [
    {
      name: "Dashboard",
      identifier: "dashboard",
      disabledPermissions: ["add/edit", "export", "delete"],
    },
    { name: "Client Users", identifier: "Client Users" },
    { name: "Employee Users", identifier: "Employee Users" },
    {
      name: "Client Invoices",
      identifier: "Client Invoices",
      disabledPermissions: ["delete"],
    },
    {
      name: "Employee Invoices",
      identifier: "Employee Invoices",
      disabledPermissions: ["delete", "add/edit"],
    },
    {
      name: "User Management",
      identifier: "user-management",
      disabledPermissions: ["export"],
    },
    {
      name: "Client Management",
      identifier: "client-management",
      disabledPermissions: ["export", "view"],
    },
    {
      name: "Expense Categories",
      identifier: "category-management",
      disabledPermissions: ["export", "view"],
    },
  ];

  const initialData = {
    role_name: initialValues?.data?.role_name || "",
    data: initialValues?.data?.role_access || [],
  };

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "/roleBusiness/create",
        token: cookies.t,
        data: {
          role_name: capitalizeLetter(values.role_name),
          role_access: values.data,
          business_id: cookies.bid,
        },
      });
      if (res) {
        toast.success("Role Created Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1, { state: { customState: "Role Management" } });
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later.", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    try {
      const res = await patchData({
        endpoint: "roleBusiness/update",
        token: cookies.t,
        params: { id: id },
        data: {
          role_name: capitalizeLetter(values.role_name),
          role_access: values.data,
          business_id: cookies.bid,
        },
      });
      if (res) {
        toast.success("Role Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1, { state: { customState: "Role Management" } });
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later.", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "roleBusiness/checkRoleName",
      token: cookies.t,
      params: { name: newVal, business_id: cookies.bid },
    });
    if (response.status && response.data) {
      setEmailError("Role Name Already Exists");
    } else {
      setEmailError(null);
    }
  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        setValues,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => (
        <>
          <Breadcrumb
            labels={[
              "Role Management",
              type === "add" ? "Create Role" : "Update Role",
            ]}
            customState="Role Management"
          />
          <Heading className="addHeaderStyle">
            {type === "add" ? "Create Role" : "Update Role"}
          </Heading>
          <div className="row mb-4 mt-3">
            <MyInput
              type="text"
              id="role_name"
              name="role_name"
              customholder="Enter Role"
              width="32%"
              customBlur={(e) => {
                if (initialValues.role_name !== values.role_name) {
                  checkEmail(values.role_name);
                }
              }}
              blurError={emailError}
              required
            />
          </div>

          <Heading className="h5 mb-3">Assign Permissions</Heading>
          <ParentComponent
            modules={modules}
            initialData={initialData}
            values={values}
            setFieldValue={setFieldValue}
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />

          <div className="d-flex w-100 justify-between align-items-center mt-3">
            <CustomButton
              text="Cancel"
              size="btn-lg"
              iconRequired={false}
              handleClick={() => {
                navigate(-1);
              }}
            />
            {((roleData && roleData?.["add/edit"]) || isOwner) && (
              <div className="d-flex gap-3 mt-4">
                <CustomButton
                  text="Reset"
                  iconRequired={false}
                  handleClick={() => {
                    setValues({
                      role_name: "",
                      data: [],
                    });
                    setSelectedPermissions({});
                  }}
                  type="btn-outline-primary"
                />
                {type === "add" ? (
                  <CustomButton
                    iconRequired={false}
                    type="btn-primary "
                    buttonType="submit"
                    handleClick={() => handleSubmit(values)}
                    disabled={!isValid || !dirty || isSaving || emailError}
                  >
                    Create Role
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                ) : (
                  <CustomButton
                    iconRequired={false}
                    size="btn-lg"
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleEdit(values)}
                    disabled={!isValid || isSaving || emailError}
                  >
                    Update Role
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

const ParentComponent = ({
  modules,
  initialData,
  values,
  setFieldValue,
  selectedPermissions,
  setSelectedPermissions,
}) => {
  const [selectAll, setSelectAll] = useState({
    view: false,
    "add/edit": false,
    export: false,
    delete: false,
  });

  console.log("Values :", values.data);

  // Dynamically track `selectedModule` based on `values.data`
  const selectedModules = useMemo(() => {
    return modules.map((module) => {
      const selected = values.data.find((item) => item.module === module.name);
      return {
        module,
        selectedModule: selected || { module: module.name },
      };
    });
  }, [modules, values.data]);

  // const handlePermissionChange = useCallback(
  //   (permissions) => {
  //     if (permissions?.["add/edit"] && !permissions?.["view"]) {
  //       permissions = { ...permissions, view: true, addedFromAdd: true };
  //     } else if (
  //       !permissions?.["add/edit"] &&
  //       permissions.addedFromAdd &&
  //       permissions?.["view"]
  //     ) {
  //       delete permissions.addedFromAdd;
  //       permissions = { ...permissions, view: false };
  //     }
  //     const updatedPermissions = [...values.data];
  //     const index = updatedPermissions.findIndex(
  //       (item) => item.module === permissions.module
  //     );
  //     if (index > -1) {
  //       updatedPermissions[index] = permissions;
  //     } else {
  //       updatedPermissions.push(permissions);
  //     }
  //     setFieldValue("data", updatedPermissions);
  //   },
  //   [values.data, setFieldValue]
  // );

  // const handleSelectAll = (permissionType) => {
  //   console.log("permission", permissionType);
  //   const allSelected = selectedModules.every(
  //     ({ selectedModule }) => selectedModule[permissionType]
  //   );
  //   const newValue = !allSelected; // If all are selected, unselect; if some are selected, select all.

  //   // Update the selectAll state
  //   setSelectAll((prev) => ({ ...prev, [permissionType]: newValue }));

  //   // Update all modules to reflect the selectAll state
  //   const updatedPermissions = modules.map((module) => {
  //     const updatedModule = {
  //       module: module.name,
  //       [permissionType]: newValue,
  //     };

  //     // If permissionType is 'add' or 'edit', also toggle the 'view' permission
  //     if (permissionType === "add/edit") {
  //       updatedModule.view = newValue;
  //     }

  //     // console.log('sel',selectedModules)

  //     // console.log('checkk ',updatedModule.find(( module ) => module?.selectedModule.view))
  //     // if ((permissionType === "add/edit") && newValue) {
  //     //   if (!selectedModules.find(({ selectedModule }) => selectedModule.view)?.selectedModule.view) {
  //     //     updatedModule.view = true;
  //     //     updatedModule.addedFromAdd = true; // Set the flag
  //     //   }
  //     // }

  //     return updatedModule;
  //   });

  //   // Merge updated permissions with existing values
  //   const mergedPermissions = values.data.map((item) => {
  //     const update = updatedPermissions.find(
  //       (updated) => updated.module === item.module
  //     );
  //     return update ? { ...item, ...update } : item;
  //   });

  //   // Add any new modules that aren't in the current data
  //   const newModules = updatedPermissions.filter(
  //     (updated) => !values.data.some((item) => item.module === updated.module)
  //   );

  //   setFieldValue("data", [...mergedPermissions, ...newModules]);
  // };

  // const handlePermissionChange = useCallback(
  //   (permissions) => {
  //     const module = modules.find((mod) => mod.name === permissions.module);
  //     if (module?.disabledPermissions?.some((perm) => permissions[perm])) {
  //       return; // Skip changes for disabled permissions
  //     }

  //     if (permissions?.["add/edit"] && !permissions?.["view"]) {
  //       permissions = { ...permissions, view: true, addedFromAdd:  true };
  //     } else if (
  //       !permissions?.["add/edit"] &&
  //       permissions.addedFromAdd &&
  //       permissions?.["view"]
  //     ) {
  //       delete permissions.addedFromAdd;
  //       permissions = { ...permissions, view: false };
  //     }

  //     const updatedPermissions = [...values.data];
  //     const index = updatedPermissions.findIndex(
  //       (item) => item.module === permissions.module
  //     );
  //     if (index > -1) {
  //       updatedPermissions[index] = permissions;
  //     } else {
  //       updatedPermissions.push(permissions);
  //     }
  //     setFieldValue("data", updatedPermissions);
  //   },
  //   [modules, values.data, setFieldValue]
  // );

  const handlePermissionChange = useCallback(
    (permissions) => {
      const module = modules.find((mod) => mod.name === permissions.module);

      // Skip disabled permissions logic for other actions
      if (
        module?.disabledPermissions?.includes("add/edit") &&
        permissions["add/edit"]
      ) {
        return; // Skip if "add/edit" is disabled
      }

      // Handle "view" dependency when "add/edit" is selected
      if (permissions?.["add/edit"]) {
        if (!module?.disabledPermissions?.includes("view")) {
          permissions = { ...permissions, view: true, addedFromAdd: true };
        }
      } else if (permissions.addedFromAdd && permissions?.["view"]) {
        delete permissions.addedFromAdd;

        // Only disable "view" if it’s not disabled
        if (!module?.disabledPermissions?.includes("view")) {
          permissions = { ...permissions, view: false };
        }
      }

      const updatedPermissions = [...values.data];
      const index = updatedPermissions.findIndex(
        (item) => item.module === permissions.module
      );

      if (index > -1) {
        updatedPermissions[index] = permissions;
      } else {
        updatedPermissions.push(permissions);
      }
      setFieldValue("data", updatedPermissions);
    },
    [modules, values.data, setFieldValue]
  );

  // const handleSelectAll = (permissionType) => {

  //   const allSelected = selectedModules.every(
  //     ({ selectedModule, module }) =>
  //       selectedModule[permissionType] ||
  //       module.disabledPermissions?.includes(permissionType)
  //   );
  //   const newValue = !allSelected;

  //   setSelectAll((prev) => ({ ...prev, [permissionType]: newValue }));

  //   const updatedPermissions = modules
  //     .map((module) => {
  //       if (module.disabledPermissions?.includes(permissionType)) {
  //         return null; // Skip disabled permissions
  //       }

  //       const updatedModule = {
  //         module: module.name,
  //         [permissionType]: newValue,
  //       };

  //       if (permissionType === "add/edit") {
  //         updatedModule.view = newValue;
  //       }

  //       return updatedModule;
  //     })
  //     .filter(Boolean); // Remove null values

  //   const mergedPermissions = values.data.map((item) => {
  //     const update = updatedPermissions.find(
  //       (updated) => updated.module === item.module
  //     );
  //     return update ? { ...item, ...update } : item;
  //   });

  //   const newModules = updatedPermissions.filter(
  //     (updated) => !values.data.some((item) => item.module === updated.module)
  //   );

  //   setFieldValue("data", [...mergedPermissions, ...newModules]);
  // };

  const handleSelectAll = (permissionType) => {
    // Check if all modules are already selected for the given permission type
    const allSelected = selectedModules.every(
      ({ selectedModule, module }) =>
        selectedModule[permissionType] ||
        module.disabledPermissions?.includes(permissionType)
    );

    const newValue = !allSelected; // Toggle select all state

    // Update the `selectAll` state for the permission type
    setSelectAll((prev) => ({ ...prev, [permissionType]: newValue }));

    // Map over modules and update permissions
    const updatedPermissions = modules
      .map((module) => {
        // Skip modules with disabled permissions for the current type
        if (module.disabledPermissions?.includes(permissionType)) {
          return null;
        }

        const updatedModule = {
          module: module.name,
          [permissionType]: newValue,
        };

        // Handle dependent permissions
        if (
          permissionType === "add/edit" &&
          !module.disabledPermissions?.includes("view")
        ) {
          updatedModule.view = newValue; // Enable/disable view when toggling add/edit
        }

        return updatedModule;
      })
      .filter(Boolean); // Remove null values

    // Merge updated permissions with existing values
    const mergedPermissions = values.data.map((item) => {
      const update = updatedPermissions.find(
        (updated) => updated.module === item.module
      );
      return update ? { ...item, ...update } : item;
    });

    // Add any new modules that aren't in the current data
    const newModules = updatedPermissions.filter(
      (updated) => !values.data.some((item) => item.module === updated.module)
    );

    // Update the form field with the merged permissions
    setFieldValue("data", [...mergedPermissions, ...newModules]);
  };

  // Check if all checkboxes are selected for a permission type
  const checkIfAllSelected = (permissionType) => {
    // Filter out modules with the permissionType in disabledPermissions
    const validModules = selectedModules.filter(
      ({ module }) => !module.disabledPermissions?.includes(permissionType)
    );

    if (validModules.length === 0) {
      return false; // If no valid modules, return false (no checkboxes to toggle)
    }

    const allSelected = validModules.every(
      ({ selectedModule }) => selectedModule[permissionType]
    );

    const someSelected = validModules.some(
      ({ selectedModule }) => selectedModule[permissionType]
    );

    return allSelected ? true : someSelected ? null : false;
  };

  return (
    <div>
      <table className="permission-table">
        <thead>
          <tr>
            <th>Module Name</th>
            <th>
              {/* <input
                type="checkbox"
                checked={checkIfAllSelected("view")}
                onChange={() => handleSelectAll("view")}
                disabled={selectedModules.length === 0}
              /> */}
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("view")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("view")}
                >
                  {checkIfAllSelected("view") ? <></> : <MinusIcon />}
                </div>
                View
              </div>
            </th>
            <th>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("add/edit")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("add/edit")}
                >
                  {checkIfAllSelected("add/edit") ? <></> : <MinusIcon />}
                </div>
                Add/Edit
              </div>
            </th>
            <th>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("export")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("export")}
                >
                  {checkIfAllSelected("export") ? <></> : <MinusIcon />}
                </div>
                Export
              </div>
            </th>
            <th>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("delete")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("delete")}
                >
                  {checkIfAllSelected("delete") ? <></> : <MinusIcon />}
                </div>
                Delete
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedModules.map(({ module, selectedModule }) => (
            <PermissionSelector
              key={module.name}
              label={module.name}
              identifier={module.identifier}
              selected={selectedModule}
              onChange={handlePermissionChange}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
              disabledPermissions={module.disabledPermissions}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddRole;
