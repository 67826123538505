import React, { useEffect, useState } from "react";
import "../Home/styles.scss";
import "./styles.scss";
import InfoCard from "../../../atoms/InfoCard";

// import Business from "../../../icons/Business";
// import Payment from "../../../icons/Payment";
// import Email from "../../../icons/Email";
// import Message from "../../../icons/Message";
import { Heading } from "../../../atoms/Heading";
import EyeIcon from "../../../icons/eye.icon";
import Counterparty from "../../../icons/Counterparty";
import { useCookies } from "react-cookie";
import { getData } from "../../../../Services";
import Notes from "../../../icons/Notes";
import Payments from "../../../icons/Payments";
import VisitorChartContainer from "./InvoiceChart";
import BillsTable from "./Table";
import { endOfDay, subDays } from "date-fns";
import ArrowDown from "../../../icons/ArrowDown";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";
import { currencyData, currencySymbol } from "../../../../helpers/currencyData";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import CalenderIcon from "../../../icons/calender";
import DatePickerModal from "../../../atoms/DatePopOver";

const EmpHome = () => {
  const [cookies] = useCookies(["t", "bid", "eid", "cid", "mode"]);
  const getMode = cookies && cookies?.mode ? atob(cookies.mode) : "";
  const [businessData, setBusinessData] = useState();
  const [currency, setCurrency] = useState("USD");
  const screenWidth = useScreenWidth();
  const [date, setDate] = useState({
    start_date: subDays(new Date(), 30),
    end_date: endOfDay(new Date()),
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDateChange = (selectedDates) => {
    if (selectedDates && selectedDates?.[0] && selectedDates?.[1])
      setDate({
        start_date: selectedDates?.[0],
        end_date: selectedDates?.[1],
      });
  };
  const GetbusinessData = async () => {
    const start_date = new Date(date?.start_date);
    const end_date = new Date(date?.end_date);
    const finalStart = new Date(
      Date.UTC(
        start_date.getUTCFullYear(),
        start_date.getUTCMonth(),
        start_date.getUTCDate(),
        0, // 00:00:00 UTC
        0,
        0,
        0
      )
    ).toISOString();

    const finalEnd = new Date(
      Date.UTC(
        end_date.getUTCFullYear(),
        end_date.getUTCMonth(),
        end_date.getUTCDate(),
        23, // 23:59:59.999 UTC
        59,
        59,
        999
      )
    ).toISOString();
    try {
      const res = await getData({
        endpoint: `employee/${
          getMode === "Client" ? "getClientStats" : "getEmployeeStats"
        }`,
        params: {
          start_date: finalStart,
          end_date: finalEnd,
          currency: currency ? currency.toLowerCase() : "usd",
          business_id: cookies.bid,
          [getMode !== "Client" ? "employee_id" : "client_id"]:
            getMode !== "Client" ? cookies.eid : cookies.cid,
          user_type: getMode,
        },
        token: cookies.t,
      });
      setBusinessData(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    GetbusinessData();
  }, [date, currency]);
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Heading className="HeadingStyle"> Dashboard</Heading>
        <div className="d-flex gap-2 align-items-center">
          {/* <MyDropdown
            // placeholder={"Select Currency"}
            value={"USD"}
            width="10vw"
            selectedValue={currency}
            data={currencyData}
            onChange={(option) => setCurrency(option)}
          /> */}
          <div className="dropdown dropdown2 scale-down-dropdown">
            <div
              className="dropdown-box dropdown2-box"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {currency}
              {/* <i className="ms-1 d-flex"> */}
              <ArrowDown />
              {/* </i> */}
            </div>

            <ul className="dropdown-menu dropdown2-menu">
              {currencyData?.map((option) => (
                <li key={option.value}>
                  <button
                    className="dropdown-item dropdown-item2"
                    onClick={() => setCurrency(option.value)}
                  >
                    <span>{option.value}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {screenWidth > 900 ? (
            <CustomDateRangePicker setDate={setDate} date={date} />
          ) : (
            <div>
              <button
                style={{ background: "none", border: "none", outline: "none" }}
                onClick={() => setModalOpen(true)}
              >
                <CalenderIcon />
              </button>

              <DatePickerModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onDateChange={handleDateChange}
                selectedDate={date}
              />
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-container">
        <div className="widgets">
          <InfoCard
            color="#B1E5FC"
            title="Total Bills"
            data={businessData?.bills || 0}
            icon={<Counterparty fill="black" />}
            iconSize={"18px"}
          />
          <InfoCard
            color="#FFD88D"
            title="Total Bills Pending"
            data={businessData?.new || 0}
            iconSize={"18px"}
            icon={<Payments color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["New/Pending"] } }}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Total Bills Raised Query"
            data={businessData?.query || 0}
            iconSize={"18px"}
            icon={<Notes color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["Query Raised"] } }}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Total Bills Closed"
            data={businessData?.booked || 0}
            iconSize={"18px"}
            icon={<Payments color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["Booked"] } }}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Total Bills Rejected"
            data={businessData?.rejected || 0}
            iconSize={"18px"}
            icon={<Notes color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["Rejected"] } }}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Expenses Pending"
            data={businessData?.expenseNew?.toFixed(2) || 0}
            iconSize={"18px"}
            icon={<Payments color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["New/Pending"] } }}
            unit={currencySymbol[currency]}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Expenses Raised Query"
            data={businessData?.expenseRaised?.toFixed(2) || 0}
            iconSize={"18px"}
            icon={<Notes color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["Query Raised"] } }}
            unit={currencySymbol[currency]}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Expenses Closed"
            data={businessData?.expenseBooked?.toFixed(2) || 0}
            iconSize={"18px"}
            icon={<Payments color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["Booked"] } }}
            unit={currencySymbol[currency]}
            // data={data?.total_company || 0}
          />
          <InfoCard
            color="#FFD88D"
            title="Expenses Rejected"
            data={businessData?.expenseRejected?.toFixed(2) || 0}
            iconSize={"18px"}
            icon={<Notes color="black" />}
            link="/dashboard/bills"
            state={{ filters: { Status: ["Rejected"] } }}
            unit={currencySymbol[currency]}
            // data={data?.total_company || 0}
          />
        </div>
        <div className="common">
          <div className="mt-2">
            <VisitorChartContainer />
          </div>
        </div>
        <div className="common">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <Heading className={"HeadingSubStyle"}>Bills Summary</Heading>
          </div>
          <div className="mt-2">
            <BillsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpHome;
