import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../atoms/Checkbox";
import "./styles.scss";
// const PermissionSelector = ({ label, onChange, selected }) => {
//   const [selectedPermissions, setSelectedPermissions] = useState({});

//   const types = ["view", "add/edit", "export", "delete"];
//   useEffect(() => {
//     if (selected && typeof selected === "object") {
//       setSelectedPermissions(selected);
//     }
//   }, [selected]);

//   const handleCheckboxChange = (type) => {
//     const updatedPermissions = {
//       ...selectedPermissions,
//       [type]: !selectedPermissions[type],
//     };

//     setSelectedPermissions(updatedPermissions);

//     if (onChange) {
//       onChange({ ...updatedPermissions, module: label });
//     }
//   };

//   return (
//     <tr className="permission_selector">
//       <td>{label}</td>
//       {types.map((type) => (
//         <td key={type}>
//           <Checkbox
//             name={type}
//             checked={!!selectedPermissions[type]}
//             onChange={() => handleCheckboxChange(type)}
//           />
//         </td>
//       ))}
//     </tr>
//   );
// };

const PermissionSelector = ({
  label,
  onChange,
  selected,
  disabledPermissions,
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const types = ["view", "add/edit", "export", "delete"];
  useEffect(() => {
    if (selected && typeof selected === "object") {
      setSelectedPermissions(selected);
    }
  }, [selected]);

  const handleCheckboxChange = (type) => {
    const updatedPermissions = {
      ...selectedPermissions,
      [type]: !selectedPermissions[type],
    };

    setSelectedPermissions(updatedPermissions);

    if (onChange) {
      onChange({ ...updatedPermissions, module: label });
    }
  };

  return (
    <tr className="permission_selector">
      <td>{label}</td>
      {types.map((type) => (
        <td key={type}>
          <Checkbox
            name={type}
            disabled={disabledPermissions?.includes(type)}
            checked={!!selectedPermissions[type]}
            onChange={() => handleCheckboxChange(type)}
          />
        </td>
      ))}
    </tr>
  );
};

export default PermissionSelector;
