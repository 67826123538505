import NormalTextInput from ".";

const NormalMyInput = ({ width = "100%", className = "", info, ...props }) => {
  return (
    <div style={{ width: width }} className={className}>
      <NormalTextInput info={info} {...props} />
    </div>
  );
};
export default NormalMyInput;
