import React from "react";
import { useNavigate } from "react-router-dom";
// import IconCircle from "../avatars/IconCircle";
import "./styles.scss";
import IconCircle from "../../atoms/IconCircle/index";
import useScreenWidth from "../../../hooks/useScreenwidth";

const InfoCard = ({
  icon,
  svgIcon,
  isSvg,
  color,
  title,
  data,
  unit,
  link,
  style,
  state,
  dotIcon,
  setModalState,
  iconSize,
}) => {
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  const onClickHandle = (e) => {
    e.preventDefault();
    if (link) navigate(link, { state: { customState: state } });
  };

  return (
    <div
      className="info-card cursor-pointer"
      onClick={onClickHandle}
      style={style}
    >
      <div className="info-card-detail">
        <div className="d-flex flex-column">
          <span className="title" style={{ fontSize: "12px" }}>
            {title}
          </span>
          <span
            className="text-dark heading"
            style={{ fontSize: "18px", fontWeight: "800" }}
          >
            {data}&nbsp;<span className="p-lg">{unit ? unit : ""}</span>
          </span>
        </div>
        {screenWidth > 600 && (
          <>
            {isSvg ? (
              <IconCircle
                svgIcon={svgIcon}
                color={color}
                size="56px"
                iconSize="18px"
                isSvg={true}
              />
            ) : (
              <IconCircle
                icon={icon}
                color={color}
                size="40px"
                iconSize={iconSize}
              />
            )}
          </>
        )}
      </div>
      {dotIcon && (
        <div
          className={"dotIconWrap"}
          onClick={() => {
            setModalState({
              modalTitle: "Products",
              isModalOpen: true,
            });
          }}
        >
          <i className="fi fi-br-menu-dots-vertical"></i>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
