import React, { useEffect, useState } from "react";
// import PageCard from "../../../../atoms/PageCard";
import SelectDropdown from "../../../../atoms/MyDropdown";
import VisitorChart from "./chart";
import DateRangePickerShortCuts from "../../../../atoms/DateRangePicker";
import addMonths from "date-fns/addMonths";
import { getData } from "../../../../../Services/index";
import { useCookies } from "react-cookie";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
  subDays,
  endOfDay,
} from "date-fns";
import dayjs from "dayjs";
import "./stles.scss";
import CustomDateRangePicker from "../../../../atoms/DateRangePicker";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { invoiceStatus, modeData } from "../../../../../helpers/staticData";
import MyDropdown from "../../../../atoms/MyDropdown";
import { Heading } from "../../../../atoms/Heading";
import DatePickerModal from "../../../../atoms/DatePopOver";
import CalenderIcon from "../../../../icons/calender";

const VisitorChartContainer = ({ venueData }) => {
  const [cookies] = useCookies(["b_t", "bid"]);
  const getMode = cookies && cookies?.mode ? atob(cookies.mode) : "";
  const defaultToday = new Date();
  const screenWidth = useScreenWidth();
  const [filters, setFilters] = useState({});
  const [clientList, setClientList] = useState();
  const [employeeList, setEmployeeList] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [date, setDate] = useState({
    start_date: subDays(new Date(), 30),
    end_date: endOfDay(new Date()),
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDateChange = (selectedDates) => {
    if (selectedDates && selectedDates?.[0] && selectedDates?.[1])
      setDate({
        start_date: selectedDates?.[0],
        end_date: selectedDates?.[1],
      });
  };
  // console.log('test', addDays(date?.start_date , 1).toISOString())

  const [graphData, setGraphData] = useState();

  const handleGraphUpdation = async () => {
    const res = await getData({
      endpoint: "businessDashboard/getInvoiceStats",
      params: {
        business_id: cookies.bid,
        start_date: date?.start_date,
        end_date: date?.end_date,
        categories: filters?.["Category"],
        bill_status: filters?.["Status"],
        [getMode !== "Client" ? "employees" : "clients"]:
          getMode !== "Client" ? [cookies.eid] : [cookies.cid],
        user_type: getMode !== "Client" ? "Employee" : "Client",
      },
      token: cookies.t,
    });

    if (res) {
      setGraphData(res?.counts);
    }
  };

  const getCategoryList = async () => {
    const res = await getData({
      endpoint: "category/getCategoryList",
      token: cookies.t,
      params: { business_id: cookies.bid },
    });
    setCategoryData(res);
  };

  useEffect(() => {
    handleGraphUpdation();
    getCategoryList();
  }, []);

  const filterData = {
    Category: categoryData,
    Status: invoiceStatus,
  };

  return (
    <>
      <style>
        {`
      @media (max-width: 1000px) {
            .pagecard-responsive-tab {
              padding: 1rem;
            }
            .rmove-heder-margin{
            width:100%;
            h3{
            margin-bottom:0 !important;
            }
            }

            .drop-items-hone-tab {
              width: 100%;
              justify-content: space-between;
            }
              .rs-picker-daterange-panel{
              }
          }
        `}
      </style>
      <div className="line-chart-invoices w-100">
        <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
          <div className="d-flex justify-content-between align-items-center mb-2 rmove-heder-margin">
            <Heading className={"HeadingSubStyle"}>Bills Stats</Heading>
            <div className="none-button-responsive-add">
              <div>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <CalenderIcon />
                </button>

                <DatePickerModal
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                  onDateChange={handleDateChange}
                  selectedDate={date}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center drop-items-hone-tab">
            <div className="none-button-responsive">
              <CustomDateRangePicker setDate={setDate} date={date} />
            </div>

            <FilterComponent
              filterData={filterData}
              selectedFilters={filters}
              onFilterChange={(filter) => {
                setFilters(filter);
              }}
              mode="Invoice"
              headingMobile={screenWidth < 600 ? true : false}
              positionLeft={screenWidth < 900 ? true : false}
            />
            <button
              className="btn btn-primary tab-employee-responsive-none"
              style={{ height: "46px" }}
              onClick={() => handleGraphUpdation()}
            >
              Update Graph
            </button>
          </div>
        </div>

        <VisitorChart data={graphData} />
      </div>
    </>
  );
};

export default VisitorChartContainer;
