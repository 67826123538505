import React, { useEffect, useState } from "react";
// import PageCard from "../../../../atoms/PageCard";
import SelectDropdown from "../../../../atoms/MyDropdown";
import VisitorChart from "./chart";
import DateRangePickerShortCuts from "../../../../atoms/DateRangePicker";
import addMonths from "date-fns/addMonths";
import { getData } from "../../../../../Services/index";
import { useCookies } from "react-cookie";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
  set,
  subDays,
  endOfDay,
} from "date-fns";
import dayjs from "dayjs";
import "./stles.scss";
import CustomDateRangePicker from "../../../../atoms/DateRangePicker";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { invoiceStatus, modeData } from "../../../../../helpers/staticData";
import MyDropdown from "../../../../atoms/MyDropdown";
import { Heading } from "../../../../atoms/Heading";
import ArrowDown from "../../../../icons/ArrowDown";
import { currencySymbol } from "../../../../../helpers/currencyData";

const VisitorChartContainer = ({ currency, is_emp_user, is_client_user }) => {
  const [cookies] = useCookies(["b_t", "bid"]);

  const defaultToday = new Date();
  const screenWidth = useScreenWidth();
  const [modeVale, setModeValue] = useState("All");
  const options =
    is_emp_user && is_client_user
      ? ["All", "Client", "Employee"]
      : is_emp_user
      ? ["Employee"]
      : ["Client"];
  const [filters, setFilters] = useState({});
  const [clientList, setClientList] = useState();
  const [employeeList, setEmployeeList] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [clientNameList, setClientNameList] = useState([]);

  const [date, setDate] = useState({
    start_date: subDays(new Date(), 30),
    end_date: endOfDay(new Date()),
  });
  // console.log('test', addDays(date?.start_date , 1).toISOString())

  const [graphData, setGraphData] = useState();

  const handleGraphUpdation = async () => {
    const start_date = new Date(date?.start_date);
    const end_date = new Date(date?.end_date);
    const finalStart = new Date(
      Date.UTC(
        start_date.getUTCFullYear(),
        start_date.getUTCMonth(),
        start_date.getUTCDate(),
        0, // 00:00:00 UTC
        0,
        0,
        0
      )
    ).toISOString();

    const finalEnd = new Date(
      Date.UTC(
        end_date.getUTCFullYear(),
        end_date.getUTCMonth(),
        end_date.getUTCDate(),
        23, // 23:59:59.999 UTC
        59,
        59,
        999
      )
    ).toISOString();
    const res = await getData({
      endpoint: "businessDashboard/getInvoiceStats",
      params: {
        business_id: cookies.bid,
        start_date: finalStart,
        end_date: finalEnd,
        start_price: filters?.textField10 ? filters?.textField10 : null,
        end_price: filters?.textField20 ? filters?.textField20 : null,
        employees: filters?.["Employee Users"],
        client_names: filters?.["Clients"],
        clients: filters?.["Client Users"],
        categories: filters?.["Category"],
        bill_status: filters?.["Status"],
        user_type: modeVale,
        currency: currency.toLowerCase(),
        group_id: cookies.group_id ? cookies.group_id : "",
      },
      token: cookies.t,
    });

    if (res) {
      setGraphData(res?.counts);
    }
  };

  const getClientsList = async () => {
    const res = await getData({
      endpoint: "cusers/getEmployeeList",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    setClientList(res);
  };

  const getClientsByEmployees = async () => {
    const res = await getData({
      endpoint: "cusers/getClientsByEmployees",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    setClientNameList(res);
  };

  const getEmployeeList = async () => {
    const res = await getData({
      endpoint: "employee/getEmployeeList",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
      },
    });
    setEmployeeList(res);
  };

  const getCategoryList = async () => {
    const res = await getData({
      endpoint: "category/getCategoryList",
      token: cookies.t,
      params: { business_id: cookies.bid },
    });
    setCategoryData(res);
  };

  useEffect(() => {
    if (is_emp_user || is_client_user) {
      handleGraphUpdation();
      getCategoryList();
    }
    if (is_client_user) {
      getClientsList();
      getClientsByEmployees();
    }
    if (is_emp_user) {
      getEmployeeList();
    }
  }, []);

  const filterData = {
    ...(is_client_user
      ? { Clients: clientNameList, ["Client Users"]: clientList }
      : {}),
    ...(is_emp_user
      ? {
          ["Employee Users"]: employeeList,
        }
      : {}),
    Category: categoryData,
    Status: invoiceStatus,
  };

  return (
    <>
      <style>
        {`
      @media (min-width: 500px) and (max-width: 1230px) {
            .pagecard-responsive-tab {
              padding: 1rem;
            }
            .rmove-heder-margin{
            width:100%;
            h3{
            margin-bottom:0 !important;
            }
            }

            .drop-items-hone-tab {
              width: 100%;
              justify-content: space-between;
            }
              .rs-picker-daterange-panel{
              }
          }
        `}
      </style>
      <div className="line-chart-invoices">
        <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
          <div className=" w-100 d-flex justify-content-between align-items-center mb-2 rmove-heder-margin">
            <Heading className={"HeadingSubStyle"}>Invoices Summary</Heading>
            <div className="d-flex gap-3 align-items-center drop-items-hone-tab">
              <div className="dropdown dropdown2">
                <div
                  className="dropdown-box dropdown2-box"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {modeVale}
                  {/* <i className="ms-1 d-flex"> */}
                  <ArrowDown />
                  {/* </i> */}
                </div>

                <ul className="dropdown-menu dropdown2-menu">
                  {options?.map((option) => (
                    <li key={option}>
                      <button
                        className="dropdown-item dropdown-item2"
                        onClick={() => setModeValue(option)}
                      >
                        <span>{option}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <FilterComponent
                filterData={filterData}
                selectedFilters={filters}
                textInputNames={["Price Range"]}
                onFilterChange={(filter) => {
                  setFilters(filter);
                }}
                mode="Invoice"
                unit={currencySymbol[currency]}
              />
              <div>
                <CustomDateRangePicker setDate={setDate} date={date} />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center  drop-items-hone-tab">
            <button
              className="btn btn-primary tab-employee-responsive-none"
              style={{ height: "46px" }}
              onClick={() => handleGraphUpdation()}
            >
              Update Graph
            </button>
          </div>
        </div>

        <VisitorChart data={graphData} />
      </div>
    </>
  );
};

export default VisitorChartContainer;
