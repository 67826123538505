import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../Services";
import {
  invoiceStatusMulti,
  modeData,
  modeDataMulti,
  statusData,
  statusData2,
} from "../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import MyInput from "../../../atoms/MyInput";
import MyDropdown from "../../../atoms/MyDropdown";
import { emailRegex } from "../../../../helpers/expirationTime";
import { useSelector } from "react-redux";
import { capitalizeLetter } from "../../../../helpers/capatalizeLetters";
import MultipleUserSelectComponent from "../../../atoms/TagMultiSelect";
import { DateSelector } from "../../../atoms/MonthYearSelector";

const UserForm = ({ onClose, initialValues, id, refetchData, setEditData }) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [rolesData, setRolesData] = useState(false);
  const [roleGroupData, setRoleGroupData] = useState();
  const [emailError, setEmailError] = useState("Varun");
  const [clientList, setClientList] = useState();
  const [clientuserList, setClientUserList] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [employeeList, setEmployeeList] = useState();
  const [userType, setUserType] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [employeeUsers, setEmployeeUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);
  const [reportType, setReportType] = useState("Expenses");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isClientsChanged, setisClientsChanged] = useState(false);
  const [isUserTypeChanged, setIsUserTypeChanged] = useState(false);
  const [reportData, setReportData] = useState({
    user_type: [],
    clients: [],
    client_users: [],
    employee_users: [],
    categories: [],
    status: [],
    start_date: [],
    end_date: [],
  });
  const initialData = {
    report_type: "",
    report_name: "",
    user_type: "",
    clients: [],
    client_users: [],
    employee_users: [],
    categories: [],
    status: [],
    start_date: [],
    end_date: [],
  };

  const getClientsList = async () => {
    const res = await getData({
      endpoint: "cusers/getClientsByEmployees",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    if (res) {
      const transformedData = res.map((item) => ({
        emp_id: item.value,
        name: item.label,
        tag: item?.tag,
      }));
      setClientList(transformedData);
    }
  };

  const getClientsUsersList = async () => {
    const res = await getData({
      endpoint: "cusers/getEmployeeList",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    if (res) {
      const transformedData = res.map((item) => ({
        emp_id: item.value,
        name: item.label,
        tag: item?.tag,
      }));
      setClientUserList(transformedData);
    }
  };

  const getEmployeeList = async () => {
    const res = await getData({
      endpoint: "employee/getEmployeeList",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
      },
    });
    if (res) {
      const transformedData = res.map((item) => ({
        emp_id: item.value,
        name: item.label,
        tag: item?.tag,
      }));
      setEmployeeList(transformedData);
    }
  };

  console.log("Clientusers :", clientUsers);

  const employeeListByClients = async (option) => {
    console.log("Selected option : ", option);
    setisClientsChanged(true);
    if (option.length === 0) {
      getClientsUsersList();
    }
    if (option && option.length > 0) {
      setClientUsers([]);
      const res = await getData({
        endpoint: "cusers/getEmployeeListByClients",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
          clients: option,
        },
      });
      if (res) {
        const transformedData = res.map((item) => ({
          emp_id: item.value,
          name: item.label,
          tag: item?.tag,
        }));
        setClientUserList(transformedData);
      }
    }
  };

  const getCategoryList = async () => {
    const res = await getData({
      endpoint: "category/getCategoryList",
      token: cookies.t,
      params: { business_id: cookies.bid },
    });
    if (res) {
      const transformedData = res.map((item) => ({
        emp_id: item.value,
        name: item.label,
        tag: item?.tag,
      }));
      setCategoryData(transformedData);
    }
  };

  useEffect(() => {
    getClientsList();
    getEmployeeList();
    getClientsUsersList();
    getCategoryList();
  }, []);

  const validationSchema = Yup.object({});

  const handleSubmit = async (values, { resetForm }) => {
    const res = await postData({
      endpoint: "reports/create",
      token: cookies.t,
      data: {
        business_id: cookies.bid,
        user_id: cookies.uid,
        report_type: reportType,
        categories: categories && categories.length > 0 ? categories : null,
        bill_status: status && status.length > 0 ? status : null,
        start_date: startDate,
        end_date: endDate,
        employees:
          employeeUsers && employeeUsers.length > 0 ? employeeUsers : null,
        clients: clientUsers && clientUsers.length > 0 ? clientUsers : null,
        client_names: clients && clients.length > 0 ? clients : null,
        user_type: userType && userType.length === 2 ? "All" : userType[0],
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("User Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => (
        <Form autoComplete="off">
          <div
            className="d-flex flex-wrap w-100 mt-3"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <div style={{ width: "23.2%" }}>
              <MyDropdown
                data={[{ label: "Expenses", value: "Expenses" }]}
                placeholder="Select Report Type"
                onChange={(option) => {
                  setReportType(option);
                }}
                selectedValue={reportType}
                isDisabled
              />
            </div>
            <div style={{ width: "23.2%" }}>
              <MultipleUserSelectComponent
                title={"Select User Type"}
                data={modeDataMulti}
                onSelect={(option) => {
                  //   setReportData({ ...reportData, user_type: option });
                  if (!userType.includes("Employee")) {
                    setEmployeeUsers([]);
                  }
                  if (!userType.includes("Clients")) {
                    setClients([]);
                    setClientUsers([]);
                  }
                  // if (userType !== option) {
                  //   setIsUserTypeChanged(true);
                  // }
                  setUserType(option);
                }}
                selectAllLabel="All"
                selectedEmployeeIds={userType}
              />
            </div>
            {(userType?.includes("All") || userType?.includes("Client")) && (
              <div style={{ width: "23.2%" }}>
                <MultipleUserSelectComponent
                  title={"Select Clients"}
                  data={clientList}
                  onSelect={(option) => {
                    // setReportData({ ...reportData, clients: option });
                    // setClientUserList([]);
                    // if (!isUserTypeChanged && !isClientsChanged) {
                    setClients(option);
                    // }
                    if (option !== clients) {
                      employeeListByClients(option);
                    }
                  }}
                  selectAllLabel="All"
                  selectedEmployeeIds={clients}
                />
              </div>
            )}
            {(userType?.includes("All") || userType?.includes("Client")) && (
              <div style={{ width: "23.2%" }}>
                <MultipleUserSelectComponent
                  title={"Select Client Users"}
                  data={clientuserList}
                  onSelect={(option) => {
                    // if (!isUserTypeChanged) {
                    // if (option !== clientUsers) {
                    setClientUsers(option);
                    // }
                    // }
                  }}
                  selectAllLabel="All"
                  selectedEmployeeIds={clientUsers}
                />
              </div>
            )}
            {(userType?.includes("All") || userType?.includes("Employee")) && (
              <div style={{ width: "23.2%" }}>
                <MultipleUserSelectComponent
                  title={"Select Employee Users"}
                  data={employeeList}
                  onSelect={(option) => {
                    setEmployeeUsers(option);
                  }}
                  selectAllLabel="All"
                  selectedEmployeeIds={employeeUsers}
                />
              </div>
            )}
            <div style={{ width: "23.2%" }}>
              <MultipleUserSelectComponent
                title={"Select Categories"}
                data={categoryData}
                onSelect={(option) => {
                  setCategories(option);
                }}
                selectAllLabel="All"
                selectedEmployeeIds={categories}
              />
            </div>
            <div style={{ width: "23.2%" }}>
              <MultipleUserSelectComponent
                title={"Select Status"}
                data={invoiceStatusMulti}
                onSelect={(option) => {
                  setStatus(option);
                }}
                selectAllLabel="All"
                selectedEmployeeIds={status}
              />
            </div>
            <div style={{ width: "23.2%", position: "relative" }}>
              <DateSelector
                placeholder="Start Date"
                onChange={async (option) => {
                  setStartDate(option);
                }}
                selectedDate={startDate}
              />
            </div>
            <div style={{ width: "23.2%", position: "relative" }}>
              <DateSelector
                placeholder="End Date"
                onChange={async (option) => {
                  setEndDate(option);
                }}
                selectedDate={endDate}
              />
            </div>
            {((roleData && roleData?.["add/edit"]) || isOwner) && (
              <div className={`col-3 `}>
                {initialValues ? (
                  <button
                    type="submit"
                    className="btn-smlg btn-primary w-50"
                    disabled={!isValid || isSubmitting}
                  >
                    Update
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                ) : (
                  <div className="d-flex gap-4 ">
                    <button
                      type="button"
                      className="btn-smlg btn-outline-primary w-25 flex-1"
                      disabled={isSubmitting}
                      onClick={() => {
                        setClientUsers([]);
                        setEmployeeUsers([]);
                        setClients([]);
                        setCategories([]);
                        setStatus([]);
                        setStartDate(null);
                        setEndDate([]);
                        setUserType([]);
                      }}
                    >
                      Reset
                    </button>

                    <button
                      type="submit"
                      className="btn-smlg btn-primary w-25 flex-1"
                      disabled={
                        isSubmitting ||
                        !(
                          userType?.length > 0 ||
                          clients?.length > 0 ||
                          clientUsers?.length > 0 ||
                          employeeUsers?.length > 0 ||
                          status?.length > 0 ||
                          categories?.length > 0 ||
                          startDate !== null ||
                          endDate !== null
                        )
                      }
                    >
                      Create
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
