import React, { useEffect, useState } from "react";
import { DateSelector } from "../MonthYearSelector"; // Adjust based on your actual import path
import "./styles.scss";
import { Heading } from "../Heading";
const DatePickerModal = ({ isOpen, onClose, onDateChange, selectedDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSave = () => {
    if (startDate && endDate) {
      onDateChange([startDate, endDate]); // Send back the dates as an array
      onClose();
    }
  };

  useEffect(() => {
    if (selectedDate && selectedDate?.start_date && selectedDate?.end_date) {
      setStartDate(new Date(selectedDate?.start_date));
      setEndDate(new Date(selectedDate?.end_date));
    }
  }, [selectedDate]);

  const handleCancel = () => {
    onClose(); // Close the modal without saving
  };

  return isOpen ? (
    <div className="random-overlay-class">
      <div className="random-modal-content-class">
        <div className="random-modal-header-class">
          <Heading className="h5 mt-1 lh-1 mb-0">Select Dates</Heading>
        </div>
        <div className="random-modal-body-class">
          <div style={{ position: "relative", width: "100%" }}>
            <DateSelector
              placeholder="Start Date"
              onChange={(date) => setStartDate(date)}
              selectedDate={startDate}
            />
          </div>
          <div style={{ position: "relative", width: "100%" }}>
            <DateSelector
              selectedDate={endDate}
              onChange={(date) => setEndDate(date)}
              placeholder="End Date"
            />
          </div>
        </div>
        <div className="random-modal-footer-class">
          <button onClick={handleSave} className="btn-smlg btn-primary w-25">
            Save
          </button>
          <button
            onClick={handleCancel}
            className="btn-smlg btn-outline-primary w-25"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default DatePickerModal;
