import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../icons/FilterIcon";
import CustomButton from "../../atoms/CustomButton";
import Close from "../../icons/Close";
import "./filterComponent.scss";
import CheckboxWrapper from "../../atoms/CheckBoxWrapper/Checkbox";
import { DateSelector } from "../../atoms/MonthYearSelector";

// const FilterComponent = ({
//   onFilterChange,
//   filterData: propsFilterData,
//   dateRangeNames,
//   selectedFilters = {},
//   isDateRange = false,
//   headingMobile,
//   headingText = "Filter",
//   mode = "",
// }) => {
//   const containerRef = useRef(null);
//   const dropdownRef = useRef(null);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [updatedFilterData, setUpdatedFilterData] = useState({});

//   const filterData = propsFilterData || {};

//   const updated = dateRangeNames?.reduce((acc, name, index) => {
//     acc[name] = [`startDate${index}`, `endDate${index}`];
//     return acc;
//   }, {});

//   useEffect(() => {
//     const updatedFilterData = isDateRange
//       ? { ...updated, ...propsFilterData }
//       : propsFilterData;
//     setUpdatedFilterData(updatedFilterData);
//   }, [propsFilterData]);

//   // const updatedFilterData = isDateRange
//   //   ? { ...tempupdatedFilterData, "Date Range": ["startDate", "endDate"] }
//   //   : propsFilterData;

//   const [isOpen, setIsOpen] = useState(false);
//   const [dateRange, setDateRange] = useState({
//     startDate: null,
//     endDate: null,
//   });
//   const [selectedKey, setSelectedKey] = useState();
//   const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

//   useEffect(() => {
//     const keys = Object.keys(updatedFilterData);
//     setSelectedKey(keys[0]);
//   }, [updatedFilterData]);

//   const toggleFilterBox = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleKeyClick = (key) => {
//     setSelectedKey(key);
//   };

//   const handleOptionChange = (option) => {
//     setSelectedOptionsMap((prevOptionsMap) => {
//       const updatedOptionsMap = { ...prevOptionsMap };

//       if (!updatedOptionsMap[selectedKey]) {
//         updatedOptionsMap[selectedKey] = [];
//       }

//       if (updatedOptionsMap[selectedKey].includes(option)) {
//         updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
//           (prevOption) => prevOption !== option
//         );
//       } else {
//         updatedOptionsMap[selectedKey] = [
//           ...updatedOptionsMap[selectedKey],
//           option,
//         ];
//       }

//       return updatedOptionsMap;
//     });
//   };

//   useEffect(() => {
//     if (!isDateRange) {
//       const handleClickOutside = (event) => {
//         if (
//           isOpen &&
//           containerRef.current &&
//           !containerRef.current.contains(event.target) &&
//           dropdownRef.current &&
//           !dropdownRef.current.contains(event.target)
//         ) {
//           setIsOpen(false);
//         }
//       };

//       document.addEventListener("click", handleClickOutside);

//       return () => {
//         document.removeEventListener("click", handleClickOutside);
//       };
//     }
//   }, [isOpen]);

// useEffect(() => {
//   if (selectedFilters && mode === "Invoice") {
//     setSelectedOptionsMap(selectedFilters);
//   }
// }, [selectedFilters]);

//   const handleApply = () => {
//     const filterValues = {
//       ...selectedOptionsMap,
//       ...dateRange,
//     };

//     onFilterChange(filterValues);
//     setIsOpen(false);
//   };

//   const handleReset = () => {
//     setSelectedOptionsMap({});
//     setDateRange({ startDate: null, endDate: null });
//     setIsOpen(false);
//     onFilterChange({});
//   };

//   const handleChecked = (value) => {
//     const val =
//       selectedOptionsMap[selectedKey] &&
//       selectedOptionsMap[selectedKey].includes(value);

//     return val || false;
//   };

//   const filteredOptions = updatedFilterData[selectedKey]?.filter((option) =>
//     option.label?.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <div
//       className={
//         headingMobile
//           ? "custom-filter-container filter-mobile-container"
//           : "custom-filter-container"
//       }
//       ref={containerRef}
//     >
//       <CustomButton
//         handleClick={toggleFilterBox}
//         iconRequired
//         forMobile
//         icon={<FilterIcon />}
//         size="1"
//         // type="1"
//         type="btn-outline-dark"
//         text={headingMobile ? "" : headingText}
//         classes={`filter-contain-btn ${
//           headingMobile ? "filter-mobile-container-btn" : ""
//         }
//         ${
//           (Array.isArray(selectedOptionsMap) &&
//             selectedOptionsMap.length > 0) ||
//           (selectedOptionsMap && Object.keys(selectedOptionsMap)?.length > 0)
//             ? "active-filter"
//             : ""
//         }
//       `}
//       />

//       {isOpen && (
//         <div className="filter-box" ref={dropdownRef}>
//           <div className="filter-header">
//             <p className="header">Filters</p>

//             <span
//               className="header"
//               style={{ cursor: "pointer" }}
//               onClick={toggleFilterBox}
//             >
//               <i className="d-flex">
//                 <Close />
//               </i>
//             </span>
//           </div>
//           <div className="filter-data-box">
//             <div className="filter-key-box">
//               {Object.keys(updatedFilterData).map((key) => (
//                 <div
//                   key={key}
//                   onClick={() => handleKeyClick(key)}
//                   className={`filter-key ${
//                     selectedKey === key ? "active-key" : ""
//                   }`}
//                 >
//                   {key}
//                   {selectedOptionsMap[key]?.length > 0 && (
//                     <div className="dot-primary"> </div>
//                   )}
//                 </div>
//               ))}
//             </div>

//             <div className="filters-column">
//               {dateRangeNames?.includes(selectedKey) ? (
//                 <div>
//                   <div style={{ position: "relative" }}>
//                     <DateSelector
//                       placeholder="Start Date"
//                       onChange={(date) =>
//                         setDateRange((prev) => ({
//                           ...prev,
//                           [`startDate${dateRangeNames?.indexOf(selectedKey)}`]:
//                             date,
//                         }))
//                       }
//                       selectedDate={
//                         dateRange[
//                           `startDate${dateRangeNames?.indexOf(selectedKey)}`
//                         ]
//                       }
//                     />
//                   </div>

//                   <div className="mt-3">
//                     <div style={{ position: "relative" }}>
//                       <DateSelector
//                         placeholder="End Date"
//                         onChange={(date) =>
//                           setDateRange((prev) => ({
//                             ...prev,
//                             [`endDate${dateRangeNames.indexOf(selectedKey)}`]:
//                               date,
//                           }))
//                         }
//                         selectedDate={
//                           dateRange[
//                             `endDate${dateRangeNames.indexOf(selectedKey)}`
//                           ]
//                         }
//                       />
//                     </div>
//                   </div>
//                 </div>
//               ) : (
//                 <>
//                   <input
//                     key={selectedKey}
//                     type="text"
//                     placeholder="Search"
//                     value={searchQuery}
//                     style={{
//                       height: "35px",
//                       marginBottom: "15px",
//                       width: "100%",
//                       padding: "5px 10px",
//                       background: "#fff",
//                     }}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                   />
//                   {filteredOptions?.map((option) => (
//                     <div key={option.value} className="mb-1">
//                       <label
//                         className="checkbox_container mb-3"
//                         htmlFor={option.value}
//                       >
//                         <label htmlFor="">{option.label}</label>
//                         <CheckboxWrapper
//                           checked={handleChecked(option.value)}
//                           onChange={() => handleOptionChange(option.value)}
//                         />
//                       </label>
//                     </div>
//                   ))}
//                 </>
//               )}
//             </div>
//           </div>
//           <hr className="my-0" />
//           <div className="d-flex gap-3 p-3">
//             <button
//               className="btn btn-outline-dark mt-0 w-50"
//               onClick={handleReset}
//             >
//               Reset
//             </button>
//             <button className="btn btn-primary mt-0 w-50" onClick={handleApply}>
//               Apply
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FilterComponent;

const FilterComponent = ({
  onFilterChange,
  filterData: propsFilterData,
  dateRangeNames,
  textInputNames = [],
  selectedFilters = {},
  isDateRange = false,
  headingMobile,
  positionLeft = false,
  headingText = "Filter",
  mode = "",
  unit,
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updatedFilterData, setUpdatedFilterData] = useState({});

  const filterData = propsFilterData || {};

  const updated = dateRangeNames?.reduce((acc, name, index) => {
    acc[name] = [`startDate${index}`, `endDate${index}`];
    return acc;
  }, {});

  const textInputs = textInputNames?.reduce((acc, name, index) => {
    acc[name] = [`textField1${index}`, `textField2${index}`];
    return acc;
  }, {});

  useEffect(() => {
    const updatedFilterData = isDateRange
      ? { ...updated, ...propsFilterData }
      : propsFilterData;
    setUpdatedFilterData({ ...updatedFilterData, ...textInputs });
  }, [propsFilterData]);

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [textFields, setTextFields] = useState({});
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

  useEffect(() => {
    const keys = Object.keys(updatedFilterData);
    setSelectedKey(keys[0]);
  }, [updatedFilterData]);

  useEffect(() => {
    if (selectedFilters && mode === "Invoice") {
      setSelectedOptionsMap(selectedFilters);
    }
  }, [selectedFilters]);

  const toggleFilterBox = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
  };

  const handleOptionChange = (option) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      if (updatedOptionsMap[selectedKey].includes(option)) {
        updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
          (prevOption) => prevOption !== option
        );
      } else {
        updatedOptionsMap[selectedKey] = [
          ...updatedOptionsMap[selectedKey],
          option,
        ];
      }

      return updatedOptionsMap;
    });
  };

  const handleTextFieldChange = (field, value) => {
    setTextFields((prev) => ({
      ...prev,
      [`${field}${textInputNames?.indexOf(selectedKey)}`]: value,
    }));
  };

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      ...dateRange,
      ...textFields,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setTextFields({});
    setIsOpen(false);
    onFilterChange({});
  };

  const handleChecked = (value) => {
    const val =
      selectedOptionsMap[selectedKey] &&
      selectedOptionsMap[selectedKey].includes(value);

    return val || false;
  };

  const filteredOptions = updatedFilterData[selectedKey]?.filter((option) =>
    option.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      className={`custom-filter-container ${
        positionLeft ? "filter-mobile-container" : ""
      }`}
      ref={containerRef}
    >
      <CustomButton
        handleClick={toggleFilterBox}
        iconRequired
        forMobile
        icon={<FilterIcon />}
        size="1"
        type="btn-outline-dark"
        text={headingMobile ? "" : headingText}
        classes={`filter-contain-btn ${
          headingMobile ? "filter-mobile-container-btn" : ""
        }
        ${
          (Array.isArray(selectedOptionsMap) &&
            selectedOptionsMap.length > 0) ||
          (selectedOptionsMap && Object.keys(selectedOptionsMap)?.length > 0)
            ? "active-filter"
            : ""
        }
      `}
      />

      {isOpen && (
        <div className="filter-box" ref={dropdownRef}>
          <div className="filter-header">
            <p className="header">Filters</p>

            <span
              className="header"
              style={{ cursor: "pointer" }}
              onClick={toggleFilterBox}
            >
              <i className="d-flex">
                <Close />
              </i>
            </span>
          </div>
          <div className="filter-data-box">
            <div className="filter-key-box">
              {Object.keys(updatedFilterData).map((key) => (
                <div
                  key={key}
                  onClick={() => handleKeyClick(key)}
                  className={`filter-key ${
                    selectedKey === key ? "active-key" : ""
                  }`}
                >
                  {key}
                  {selectedOptionsMap[key]?.length > 0 && (
                    <div className="dot-primary"> </div>
                  )}
                </div>
              ))}
            </div>

            <div className="filters-column">
              {dateRangeNames?.includes(selectedKey) ? (
                <div>
                  <div style={{ position: "relative" }}>
                    <DateSelector
                      placeholder="Start Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({
                          ...prev,
                          [`startDate${dateRangeNames?.indexOf(selectedKey)}`]:
                            date,
                        }))
                      }
                      selectedDate={
                        dateRange[
                          `startDate${dateRangeNames?.indexOf(selectedKey)}`
                        ]
                      }
                    />
                  </div>

                  <div className="mt-3">
                    <div style={{ position: "relative" }}>
                      <DateSelector
                        placeholder="End Date"
                        onChange={(date) =>
                          setDateRange((prev) => ({
                            ...prev,
                            [`endDate${dateRangeNames.indexOf(selectedKey)}`]:
                              date,
                          }))
                        }
                        selectedDate={
                          dateRange[
                            `endDate${dateRangeNames.indexOf(selectedKey)}`
                          ]
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : textInputNames?.includes(selectedKey) ? (
                <div>
                  <div className="currency-input-container">
                    <input
                      type="number"
                      placeholder="Enter Min Price"
                      value={
                        textFields[
                          `textField1${textInputNames?.indexOf(selectedKey)}`
                        ] || ""
                      }
                      onChange={(e) =>
                        handleTextFieldChange("textField1", e.target.value)
                      }
                      style={{ marginBottom: "10px", width: "100%" }}
                    />
                    <div className="currency-input-content">{unit}</div>
                  </div>
                  <div className="currency-input-container">
                    <input
                      type="number"
                      placeholder="Enter Max Price"
                      value={
                        textFields[
                          `textField2${textInputNames?.indexOf(selectedKey)}`
                        ] || ""
                      }
                      onChange={(e) =>
                        handleTextFieldChange("textField2", e.target.value)
                      }
                      style={{ width: "100%" }}
                    />
                    <div className="currency-input-content">{unit}</div>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    key={selectedKey}
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    style={{
                      height: "35px",
                      marginBottom: "15px",
                      width: "100%",
                      padding: "5px 10px",
                      background: "#fff",
                    }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {filteredOptions?.map((option) => (
                    <div key={option.value} className="mb-1">
                      <label
                        className="checkbox_container mb-3"
                        htmlFor={option.value}
                      >
                        <label htmlFor="">{option.label}</label>
                        <CheckboxWrapper
                          checked={handleChecked(option.value)}
                          onChange={() => handleOptionChange(option.value)}
                        />
                      </label>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <hr className="my-0" />
          <div className="d-flex gap-3 p-3">
            <button
              className="btn btn-outline-dark mt-0 w-50"
              onClick={handleReset}
            >
              Reset
            </button>
            <button className="btn btn-primary mt-0 w-50" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
