import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import Search from "../../../atoms/Search";
import CustomButton from "../../../atoms/CustomButton";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Pagination from "../../../atoms/Pagination";
// import { setLoading } from "../../../../store/slices/loaderSlice";
import Modal from "../../../atoms/modals/Modal";
import DeleteModal from "../../../atoms/modals/DeleteModal";
import Form from "./Form";
import { getData } from "../../../../Services";
import { render } from "@testing-library/react";
import Edit from "../../../icons/EditBlue";
import Delete from "../../../icons/Delete";
import { useDelete } from "../../../../hooks/useDelete";
// import NoContentCard from "../../../atoms/NoContentCard";
// import NoContentIcon from "../../../icons/Counterparty";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { Heading } from "../../../atoms/Heading";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import { Download } from "@mui/icons-material";
import DownloadIcon from "../../../icons/download";
import ClientUserForm from "./Form";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";
import ReportCardList from "./reportCard";
import DatePickerModal from "../../../atoms/DatePopOver";
import CalenderIcon from "../../../icons/calender";

const ClientReports = () => {
  const [cookies] = useCookies(["b_t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  const [editData, setEditData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState({ start_date: null, end_date: null });
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDateChange = (selectedDates) => {
    if (selectedDates && selectedDates?.[0] && selectedDates?.[1])
      setDate({
        start_date: selectedDates?.[0],
        end_date: selectedDates?.[1],
      });
  };
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const screenWidth = useScreenWidth();

  const menu = [
    {
      icon: <DownloadIcon />,
      label: "Download",
      onClick: () => {
        setAnchorEl(null);
        const link = document.createElement("a");
        link.href = selectedRow?.link;
        link.download = "";
        link.click();
      },
    },
    ...(selectedRow?._id !== cookies?.uid
      ? [
          {
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalState({
                isDeleteModalOpen: true,
                name: `${selectedRow?.type}-${moment(
                  selectedRow?.createdAt
                ).format("YYYY-MM-DD")}`,
                modalId: selectedRow?._id,
              });
              setAnchorEl(null);
            },
          },
        ]
      : []),
  ];

  console.log("Date Entered :", date);

  const getUserData = useCallback(async () => {
    let finalStart, finalEnd;
    if (date && date?.start_date && date?.end_date) {
      const start_date = new Date(date?.start_date);
      const end_date = new Date(date?.end_date);
      finalStart = new Date(
        Date.UTC(
          start_date.getUTCFullYear(),
          start_date.getUTCMonth(),
          start_date.getUTCDate(),
          0, // 00:00:00 UTC
          0,
          0,
          0
        )
      ).toISOString();

      finalEnd = new Date(
        Date.UTC(
          end_date.getUTCFullYear(),
          end_date.getUTCMonth(),
          end_date.getUTCDate(),
          23, // 23:59:59.999 UTC
          59,
          59,
          999
        )
      ).toISOString();
    }
    setLoader(true);
    try {
      //   setLoading(true);
      const res = await getData({
        endpoint: "reports",
        params: {
          search: searchedValue,
          page: currentPage,
          limit: itemsPerPage,
          business_id: cookies.bid,
          user_id:
            cookies?.mode && atob(cookies?.mode) === "Employee"
              ? cookies.eid
              : cookies.cid,
          mode:
            cookies?.mode && atob(cookies?.mode) === "Employee"
              ? "Employee"
              : "Client",
          start_date: finalStart ? finalStart : null,
          end_date: finalEnd ? finalEnd : null,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    } finally {
      setLoader(false);
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage, date]);

  useEffect(() => {
    setLoader(false);
    getUserData();
  }, [getUserData]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Report Name",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{`${params?.row?.type}-${moment(params.row?.createdAt).format(
              "YYYY-MM-DD-hh:mm A"
            )}`}</span>
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if ((roleData && roleData?.["add/edit"] && roleData?.delete) || isOwner) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!searchedValue ? (
          <NoContentCard title="No Record Found" type="Counterparty" />
        ) : (
          <NoContentCard
            title="No Reports Added Yet!"
            tag="Add User"
            type="Counterparty"
            // {...(roleData && roleData?.["add/edit"]
            //   ? {
            //       handleClick: () => openModal("Add User"),
            //       subtitle: "Click on  Add User button to add a user",
            //     }
            //   : {})}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // add , edit , delete
  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };

  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      modalId: data._id,
      modalData: data,
      isModalOpen: true,
      title: "Edit User",
    }));
  };

  const deleteUser = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data._id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteUser("reports/delete", "Report", {
        id: modalState.modalId,
      });
      getUserData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        {/* <div className="searchButton">
          <CustomButton
            type="btn-primary"
            text="Add User"
            handleClick={() => openModal('Add User')}
          />
        </div> */}
      </div>
      <>
        <Heading className="h5 mt-2 lh-1">
          {editData?._id ? "Update" : "Create"} Report
        </Heading>

        <ClientUserForm
          id={editData?._id}
          initialValues={editData}
          refetchData={getUserData}
          setEditData={setEditData}
        />
      </>
      <div
        className={`headingSearchWrap align-items-center mt-4 ${
          screenWidth < 900 ? "" : ""
        }`}
      >
        <Heading className="h5 mt-1 lh-1 mb-0">Reports List</Heading>
        {/* <Search
          onSearchEmit={(value) => {
            setSearchedValue(value);
            if (value.length > 2) {
              if (currentPage !== 1) setCurrentPage(1);
            }
          }}
        /> */}
        {screenWidth > 900 ? (
          <CustomDateRangePicker setDate={setDate} date={date} />
        ) : (
          <div>
            <button
              style={{ background: "none", border: "none", outline: "none" }}
              onClick={() => setModalOpen(true)}
            >
              <CalenderIcon />
            </button>

            <DatePickerModal
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onDateChange={handleDateChange}
            />
          </div>
        )}
      </div>
      {screenWidth > 900 ? (
        <CustomDatagrid
          getRowId={(row) => row._id}
          rows={tableData?.data?.[0]?.roleData || []}
          columns={columns}
          // tHeight="50vh"
          loader={loader}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />
      ) : tableData && tableData?.data?.[0]?.roleData?.length > 0 ? (
        <ReportCardList
          rows={tableData?.data?.[0]?.roleData || []}
          handleStatusMenu={handleStatusMenu}
          anchorEl={anchorEl}
          selectedRow={selectedRow}
          handleClose={handleClose}
          menu={menu}
        />
      ) : (
        <CustomNoRowsOverlay />
      )}
      {tableData?.data?.[0]?.pageDetails?.count > 1 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.data?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      {/* {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: 'visible',
            cssText: 'overflow: visible !important',
          }}
          width="400px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === 'Edit User' ? modalState.modalData : null
            }
            refetchData={getUserData}
          />
        </Modal>
      )} */}
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="User"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default ClientReports;
