import React from "react";
import moment from "moment";
import "../../Bill/Card.css";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({
  row,
  handleStatusMenu,
  anchorEl,
  selectedRow,
  handleClose,
  menu,
}) => {
  const navigate = useNavigate();
  return (
    <div className="card-container">
      <div
        className="card-body"
        onClick={(data) => {
          navigate(`/dashboard/bills/view/${row?._id}`);
        }}
      >
        {row?.categoryData?.category && (
          <div className="card-item">
            <span className="card-label">Category:</span>
            <span className="card-value">{row.categoryData.category}</span>
          </div>
        )}
        {row?.type && (
          <div className="card-item">
            <span className="card-label">Sub Category:</span>
            <span className="card-value">{row.type}</span>
          </div>
        )}
        {row?.currency && (
          <div className="card-item">
            <span className="card-label">Currency:</span>
            <span className="card-value">{row.currency}</span>
          </div>
        )}
        {row?.price && (
          <div className="card-item">
            <span className="card-label">Price:</span>
            <span className="card-value">{row.price}</span>
          </div>
        )}
        {row?.createdAt && (
          <div className="card-item">
            <span className="card-label">Created On:</span>
            <span className="card-value">
              {moment(row.createdAt).format("YYYY-MM-DD")}
            </span>
          </div>
        )}
        {row?.status && (
          <div className="card-item">
            <span className="card-label">Status:</span>
            <span
              className={`card-value ${
                row.status === "New/Pending"
                  ? "text-warning"
                  : row.status === "Booked"
                  ? "text-success"
                  : row.status === "Rejected"
                  ? "text-error"
                  : "text-dark"
              }`}
            >
              {row.status === "New/Pending"
                ? "Pending"
                : row.status === "Booked"
                ? "Closed"
                : row.status === "Rejected"
                ? "Rejected"
                : "Raised Query"}
            </span>
          </div>
        )}
      </div>

      {/* Custom Menu */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && selectedRow === row}
        onClose={handleClose}
        items={menu}
      />
    </div>
  );
};

export default CategoryCard;
