import { useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { Formik, Form as FormikForm, Field } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { expitationTime } from "../../../../helpers/expirationTime";
import { postData } from "../../../../Services";
import Error from "../../../atoms/Error";
import PasswordField from "../../../atoms/PasswordField";
// import styles from "../forms.module.scss";
import { numberToNumeric } from "../../../../helpers/numberToNumeric";
import TextInput from "../../../atoms/TextInput";
import Checkbox from "../../../atoms/Checkbox";
import userIcon from "../../../icons/user.icon";
import UserIcon from "../../../icons/user.icon";
const Form = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordValue, setPasswordValue] = useState(false);
  const passwordRef = useRef(null);
  const [cookie, setCookie] = useCookies([
    "uid",
    "email",
    "role",
    "group_id",
    "bid",
    "eid",
    "iso",
    "email",
    "ibu",
    "mode",
    "cid",
  ]);
  const [pageError, setPageError] = useState(undefined);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const lowercaseEmail = values.email.toLowerCase();
      const updatedValues = { ...values, email: lowercaseEmail };
      const { data: resultData } = await postData({
        endpoint: "businessAuth/commonLogin",
        data: {},
        params: updatedValues,
      });

      if (resultData.status && resultData.response === 200) {
        setCookie("uid", resultData.data.user_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("bid", resultData.data.business_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("eid", resultData.data.employee_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("iso", resultData.data.is_owner, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("ibu", resultData.data.is_business_user, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("email", btoa(resultData.data.email), {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("role", btoa(resultData.data.role_id), {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("group_id", resultData.data.role_group_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("mode", btoa(resultData.data.mode), {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        setCookie("cid", resultData.data.client_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        localStorage.setItem("countdownStartTime", "30");
        navigate("/auth/verify");
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        toast.error(resultData.message, {
          duration: 1000,
          style: errorStyles,
        });
        if (resultData.response === 401) {
          if (resultData?.data?.limit_left > 0) {
            const triesLeft = numberToNumeric[resultData?.data?.limit_left];
            setPageError(
              `You've only ${triesLeft} tr${
                resultData?.data?.limit_left === 1 ? "y" : "ies"
              } remaining`
            );
          } else if (resultData?.data?.limit_left === 0) {
            setPageError("No attempts left");
          } else {
            setPageError(undefined);
          }
        } else {
          setPageError(undefined);
        }
      }
    } catch (error) {
      toast.error("Invalid Credentials", {
        duration: 1000,
        style: errorStyles,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <div className="mt-3 mb-3">
            <TextInput
              name="email"
              type="text"
              className="primary-input"
              placeholder=""
              customholder="Enter Email"
              autoComplete="off"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\s/g, "");
              }}
              icon={<UserIcon />}
              InputHeight="large"
              allowSpecial
            />
          </div>

          <div className="mt-2">
            <PasswordField
              id="password"
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter Password"
              className={`${
                (touched.password || isSubmitting) && errors.password
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.password || isSubmitting) && errors.password && (
              <div className="error-input-message">{errors.password}</div>
            )}
          </div>

          {pageError && <Error error={pageError} />}

          <div className="d-flex justify-between align-center my-2 mt-3">
            <Checkbox name="acceptedTerms">Remember Me</Checkbox>
            <a href={"/auth/forgot-password"} className="a-primary">
              Forgot Password?
            </a>
          </div>

          <button
            className="btn btn-primary w-100 mt-2 btn-xl"
            type="submit"
            disabled={isSubmitting}
            style={{ width: "100%" }}
          >
            {isSubmitting ? "Loading" : "Login"}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2 text-light"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
