import { useField } from "formik";
import "./style.scss";
import { useState } from "react";
import Error from "../../atoms/Error";
import Info from "../Info";

const TextInput = ({
  label,
  icon,
  InputHeight = "small",
  info,
  customBlur,
  customChange,
  blurError,
  allowSpecial = false,
  width,
  required,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [placeholder, setPlaceholder] = useState(props.customholder);

  const handleFocus = () => {
    setPlaceholder(
      props.customholder.substring(0, 5) === "Enter"
        ? props.customholder.substring(5)
        : props.customholder
    );
  };

  const handleBlur = (event) => {
    field.onBlur(event);
    if (event.target.value === "") {
      setPlaceholder(props.customholder);
    }
    if (customBlur) customBlur();
  };

  const handleChange = (e) => {
    if (!allowSpecial) {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, "");
    }
    field.onChange(e);
    if (customChange) customChange(e.target.value);
  };

  return (
    <>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <div
        className={`d-flex align-center input-container input_icon_wrap`}
        style={{ width: width }}
      >
        {icon && <div className={`input_icon`}>{icon}</div>}
        <input
          {...field}
          {...props}
          className={`${
            (meta.touched && meta.error) || blurError ? "error-input " : ""
          }${
            InputHeight === "small" ? "primary-input-small" : "primary-input"
          } `}
          placeholder=""
          onFocus={handleFocus}
          autoComplete="off"
          autocomplete="off"
          list="autocompleteOff"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label
          class={`${
            InputHeight === "small"
              ? "input-placeholder-small"
              : "input-placeholder"
          } ${required ? "required-field" : ""}`}
        >
          {placeholder}
        </label>
      </div>

      {info && !meta.error ? <Info info={info} /> : null}
      {(meta.touched && meta.error) || blurError ? (
        // <div
        //   className="error-input-message"
        //   style={{ marginTop: InputHeight === 'small' ? '4px' : '8px' }}
        // >
        //   {meta.error}
        // </div>
        <Error error={meta.error || blurError} type={InputHeight} />
      ) : null}
    </>
  );
};

export default TextInput;
