import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import Search from "../../../atoms/Search";
import CustomButton from "../../../atoms/CustomButton";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Pagination from "../../../atoms/Pagination";
// import { setLoading } from "../../../../store/slices/loaderSlice";
import Modal from "../../../atoms/modals/Modal";
import DeleteModal from "../../../atoms/modals/DeleteModal";
import Form from "./Form";
import { getData } from "../../../../Services";
import { render } from "@testing-library/react";
import Edit from "../../../icons/EditBlue";
import Delete from "../../../icons/Delete";
import { useDelete } from "../../../../hooks/useDelete";
// import NoContentCard from "../../../atoms/NoContentCard";
// import NoContentIcon from "../../../icons/Counterparty";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { Heading } from "../../../atoms/Heading";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import { Download } from "@mui/icons-material";
import DownloadIcon from "../../../icons/download";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";

const Reports = () => {
  const [cookies] = useCookies(["b_t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const [editData, setEditData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState([null, null]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        setEditData(selectedRow);
        setAnchorEl(null);
      },
    },
    ...(selectedRow?._id !== cookies?.uid
      ? [
          {
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalState({
                isDeleteModalOpen: true,
                name: selectedRow.name,
                modalId: selectedRow._id,
              });
              setAnchorEl(null);
            },
          },
        ]
      : []),
  ];

  const getUserData = useCallback(async () => {
    setLoader(true);
    try {
      //   setLoading(true);
      const res = await getData({
        endpoint: "reports",
        params: {
          search: searchedValue,
          page: currentPage,
          limit: itemsPerPage,
          business_id: cookies.bid,
          user_id: cookies.uid,
          mode: "Business",
          start_date: date?.start_date?.replace(".000", ""),
          end_date: date?.end_date?.replace(".000", ""),
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    } finally {
      setLoader(false);
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage, date]);

  useEffect(() => {
    setLoader(false);
    getUserData();
  }, [getUserData]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Report Name",
      ...(screenWidth < 900 ? { width: 180 } : { flex: 1 }),
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{`${params?.row?.type}-${moment(params.row?.createdAt).format(
              "YYYY-MM-DD"
            )}`}</span>
          </>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "link",
      headerName: "Actions",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex gap-2">
              <div>
                {params?.row?.link && (
                  <a href={params?.row?.link}>
                    <>
                      <DownloadIcon />
                    </>
                  </a>
                )}
              </div>
              <div
                onClick={() => {
                  setModalState({
                    isDeleteModalOpen: true,
                    name: `${params?.row?.type}-${moment(
                      params.row?.createdAt
                    ).format("YYYY-MM-DD")}`,
                    modalId: params?.row?._id,
                  });
                  setAnchorEl(null);
                }}
              >
                <Delete />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if ((roleData && roleData?.["add/edit"] && roleData?.delete) || isOwner) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!searchedValue ? (
          <NoContentCard title="No Record Found" type="Counterparty" />
        ) : (
          <NoContentCard
            title="No Reports Added Yet!"
            tag="Add User"
            type="Counterparty"
            // {...(roleData && roleData?.["add/edit"]
            //   ? {
            //       handleClick: () => openModal("Add User"),
            //       subtitle: "Click on  Add User button to add a user",
            //     }
            //   : {})}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // add , edit , delete
  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };

  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      modalId: data._id,
      modalData: data,
      isModalOpen: true,
      title: "Edit User",
    }));
  };

  const deleteUser = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data._id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteUser("reports/delete", "Report", {
        id: modalState.modalId,
      });
      getUserData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        {/* <div className="searchButton">
          <CustomButton
            type="btn-primary"
            text="Add User"
            handleClick={() => openModal('Add User')}
          />
        </div> */}
      </div>

      <>
        <Heading className="h5 mt-2 lh-1">
          {editData?._id ? "Update" : "Create"} Report
        </Heading>

        <Form
          id={editData?._id}
          initialValues={editData}
          refetchData={getUserData}
          setEditData={setEditData}
        />
      </>

      <div className="headingSearchWrap align-items-center mt-4">
        <Heading className="h5 mt-1 lh-1 mb-0">Reports List</Heading>
        {/* <Search
          onSearchEmit={(value) => {
            setSearchedValue(value);
            if (value.length > 2) {
              if (currentPage !== 1) setCurrentPage(1);
            }
          }}
        /> */}
        <CustomDateRangePicker setDate={setDate} date={date} />
      </div>

      <CustomDatagrid
        getRowId={(row) => row._id}
        rows={tableData?.data?.[0]?.roleData || []}
        columns={columns}
        // tHeight="50vh"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />

      {tableData?.data?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.data?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {/* {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: 'visible',
            cssText: 'overflow: visible !important',
          }}
          width="400px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === 'Edit User' ? modalState.modalData : null
            }
            refetchData={getUserData}
          />
        </Modal>
      )} */}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Report"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default Reports;
