 export const currencyData=[
    {
        "label": "INR",
        "value": "INR"
    },
    {
        "label": "USD",
        "value": "USD"
    },
    {
        "label": "GBP",
        "value": "GBP"
    },
    {
        "label": "EUR",
        "value": "EUR"
    },
    {
        "label": "CAD",
        "value": "CAD"
    },
    {
        "label": "AED",
        "value": "AED"
    },
    {
        "label": "AFN",
        "value": "AFN"
    },
    {
        "label": "ALL",
        "value": "ALL"
    },
    {
        "label": "AMD",
        "value": "AMD"
    },
    {
        "label": "ARS",
        "value": "ARS"
    },
    {
        "label": "AUD",
        "value": "AUD"
    },
    {
        "label": "AZN",
        "value": "AZN"
    },
    {
        "label": "BAM",
        "value": "BAM"
    },
    {
        "label": "BDT",
        "value": "BDT"
    },
    {
        "label": "BGN",
        "value": "BGN"
    },
    {
        "label": "BHD",
        "value": "BHD"
    },
    {
        "label": "BIF",
        "value": "BIF"
    },
    {
        "label": "BND",
        "value": "BND"
    },
    {
        "label": "BOB",
        "value": "BOB"
    },
    {
        "label": "BRL",
        "value": "BRL"
    },
    {
        "label": "BWP",
        "value": "BWP"
    },
    {
        "label": "BYN",
        "value": "BYN"
    },
    {
        "label": "BZD",
        "value": "BZD"
    },
    {
        "label": "CDF",
        "value": "CDF"
    },
    {
        "label": "CHF",
        "value": "CHF"
    },
    {
        "label": "CLP",
        "value": "CLP"
    },
    {
        "label": "CNY",
        "value": "CNY"
    },
    {
        "label": "COP",
        "value": "COP"
    },
    {
        "label": "CRC",
        "value": "CRC"
    },
    {
        "label": "CVE",
        "value": "CVE"
    },
    {
        "label": "CZK",
        "value": "CZK"
    },
    {
        "label": "DJF",
        "value": "DJF"
    },
    {
        "label": "DKK",
        "value": "DKK"
    },
    {
        "label": "DOP",
        "value": "DOP"
    },
    {
        "label": "DZD",
        "value": "DZD"
    },
    {
        "label": "EEK",
        "value": "EEK"
    },
    {
        "label": "EGP",
        "value": "EGP"
    },
    {
        "label": "ERN",
        "value": "ERN"
    },
    {
        "label": "ETB",
        "value": "ETB"
    },
    {
        "label": "GEL",
        "value": "GEL"
    },
    {
        "label": "GHS",
        "value": "GHS"
    },
    {
        "label": "GNF",
        "value": "GNF"
    },
    {
        "label": "GTQ",
        "value": "GTQ"
    },
    {
        "label": "HKD",
        "value": "HKD"
    },
    {
        "label": "HNL",
        "value": "HNL"
    },
    {
        "label": "HRK",
        "value": "HRK"
    },
    {
        "label": "HUF",
        "value": "HUF"
    },
    {
        "label": "IDR",
        "value": "IDR"
    },
    {
        "label": "ILS",
        "value": "ILS"
    },
    {
        "label": "IQD",
        "value": "IQD"
    },
    {
        "label": "IRR",
        "value": "IRR"
    },
    {
        "label": "ISK",
        "value": "ISK"
    },
    {
        "label": "JMD",
        "value": "JMD"
    },
    {
        "label": "JOD",
        "value": "JOD"
    },
    {
        "label": "JPY",
        "value": "JPY"
    },
    {
        "label": "KES",
        "value": "KES"
    },
    {
        "label": "KHR",
        "value": "KHR"
    },
    {
        "label": "KMF",
        "value": "KMF"
    },
    {
        "label": "KRW",
        "value": "KRW"
    },
    {
        "label": "KWD",
        "value": "KWD"
    },
    {
        "label": "KZT",
        "value": "KZT"
    },
    {
        "label": "LBP",
        "value": "LBP"
    },
    {
        "label": "LKR",
        "value": "LKR"
    },
    {
        "label": "LTL",
        "value": "LTL"
    },
    {
        "label": "LVL",
        "value": "LVL"
    },
    {
        "label": "LYD",
        "value": "LYD"
    },
    {
        "label": "MAD",
        "value": "MAD"
    },
    {
        "label": "MDL",
        "value": "MDL"
    },
    {
        "label": "MGA",
        "value": "MGA"
    },
    {
        "label": "MKD",
        "value": "MKD"
    },
    {
        "label": "MMK",
        "value": "MMK"
    },
    {
        "label": "MOP",
        "value": "MOP"
    },
    {
        "label": "MUR",
        "value": "MUR"
    },
    {
        "label": "MXN",
        "value": "MXN"
    },
    {
        "label": "MYR",
        "value": "MYR"
    },
    {
        "label": "MZN",
        "value": "MZN"
    },
    {
        "label": "NAD",
        "value": "NAD"
    },
    {
        "label": "NGN",
        "value": "NGN"
    },
    {
        "label": "NIO",
        "value": "NIO"
    },
    {
        "label": "NOK",
        "value": "NOK"
    },
    {
        "label": "NPR",
        "value": "NPR"
    },
    {
        "label": "NZD",
        "value": "NZD"
    },
    {
        "label": "OMR",
        "value": "OMR"
    },
    {
        "label": "PAB",
        "value": "PAB"
    },
    {
        "label": "PEN",
        "value": "PEN"
    },
    {
        "label": "PHP",
        "value": "PHP"
    },
    {
        "label": "PKR",
        "value": "PKR"
    },
    {
        "label": "PLN",
        "value": "PLN"
    },
    {
        "label": "PYG",
        "value": "PYG"
    },
    {
        "label": "QAR",
        "value": "QAR"
    },
    {
        "label": "RON",
        "value": "RON"
    },
    {
        "label": "RSD",
        "value": "RSD"
    },
    {
        "label": "RUB",
        "value": "RUB"
    },
    {
        "label": "RWF",
        "value": "RWF"
    },
    {
        "label": "SAR",
        "value": "SAR"
    },
    {
        "label": "SDG",
        "value": "SDG"
    },
    {
        "label": "SEK",
        "value": "SEK"
    },
    {
        "label": "SGD",
        "value": "SGD"
    },
    {
        "label": "SOS",
        "value": "SOS"
    },
    {
        "label": "SYP",
        "value": "SYP"
    },
    {
        "label": "THB",
        "value": "THB"
    },
    {
        "label": "TND",
        "value": "TND"
    },
    {
        "label": "TOP",
        "value": "TOP"
    },
    {
        "label": "TRY",
        "value": "TRY"
    },
    {
        "label": "TTD",
        "value": "TTD"
    },
    {
        "label": "TWD",
        "value": "TWD"
    },
    {
        "label": "TZS",
        "value": "TZS"
    },
    {
        "label": "UAH",
        "value": "UAH"
    },
    {
        "label": "UGX",
        "value": "UGX"
    },
    {
        "label": "UYU",
        "value": "UYU"
    },
    {
        "label": "UZS",
        "value": "UZS"
    },
    {
        "label": "VEF",
        "value": "VEF"
    },
    {
        "label": "VND",
        "value": "VND"
    },
    {
        "label": "XAF",
        "value": "XAF"
    },
    {
        "label": "XOF",
        "value": "XOF"
    },
    {
        "label": "YER",
        "value": "YER"
    },
    {
        "label": "ZAR",
        "value": "ZAR"
    },
    {
        "label": "ZMK",
        "value": "ZMK"
    },
    {
        "label": "ZWL",
        "value": "ZWL"
    }
]


export const currencySymbol={
    "USD": "$",
    "EUR": "€",
    "GBP": "£",
    "JPY": "¥",
    "AUD": "A$",
    "CAD": "C$",
    "CHF": "CHF",
    "CNY": "¥",
    "SEK": "kr",
    "NZD": "NZ$",
    "INR": "₹",
    "MXN": "$",
    "SGD": "S$",
    "HKD": "HK$",
    "NOK": "kr",
    "KRW": "₩",
    "TRY": "₺",
    "RUB": "₽",
    "BRL": "R$",
    "ZAR": "R",
    "MYR": "RM",
    "PHP": "₱",
    "IDR": "Rp",
    "THB": "฿",
    "VND": "₫",
    "SAR": "﷼",
    "AED": "د.إ",
    "ILS": "₪",
    "EGP": "E£",
    "NGN": "₦",
    "KES": "KSh",
    "PKR": "₨",
    "BDT": "৳",
    "LKR": "₨",
    "MMK": "K",
    "CZK": "Kč",
    "PLN": "zł",
    "HUF": "Ft",
    "DKK": "kr",
    "RON": "lei",
    "BGN": "лв",
    "HRK": "kn",
    "ISK": "kr",
    "CLP": "$",
    "PEN": "S/",
    "COP": "$",
    "ARS": "$",
    "UYU": "$U",
    "BOB": "Bs.",
    "PYG": "₲",
    "GHS": "GH₵",
    "ETB": "ብር",
    "TZS": "TSh",
    "UGX": "USh",
    "MAD": "DH",
    "DZD": "دج",
    "TND": "د.ت",
    "XAF": "FCFA",
    "XOF": "CFA",
    "MZN": "MT",
    "AOA": "Kz",
    "ZMW": "ZK",
    "BWP": "P",
    "NAD": "$",
    "SCR": "₨",
    "MUR": "₨",
    "SRD": "$",
    "FJD": "FJ$",
    "PGK": "K",
    "XCD": "EC$",
    "TTD": "TT$",
    "BSD": "$",
    "BBD": "$",
    "JMD": "J$",
    "HTG": "G",
    "BZD": "BZ$",
    "GYD": "G$",
    "KYD": "$",
    "ANG": "ƒ",
    "AWG": "ƒ"
  }
  