import "./sidebar.scss";
import Dashboard from "../../icons/dashboard";
import CustomNavLink from "./CustomNavLink";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useScreenWidth from "../../../hooks/useScreenwidth";
import CompleteLogo from "../../../assets/images/WEchartered_Logo.jpg";
import Configuration from "../../icons/Configuration";
import { useCookies } from "react-cookie";
import LeftArrow from "../../icons/LeftArrow";
import { Menu, MenuItem } from "@mui/material";
import { capitalizeLetter } from "../../../helpers/capatalizeLetters";
import UpwardArrow from "../../icons/UpwardArrow";
import Bottomarrow from "../../icons/Bottomarrow";
import CircleAvatar from "../../atoms/CircleAvatar";
import LogoutModal from "../../atoms/modals/LogoutModal";
import { decodeCookieValue } from "../../../helpers/cookieHelper";
import MyDropdown from "../../atoms/MyDropdown";
import {
  switchingClient,
  switchingConsoles,
  switchingEmployee,
} from "../../../helpers/staticData";
import { expitationTime } from "../../../helpers/expirationTime";
import CompanyLogo from "../../icons/companyLogo";
import ShortCompantLogo from "../../icons/shortCompanyLogo";
import { useSelector } from "react-redux";
import Broker from "../../icons/Broker";
import Payments from "../../icons/Payments";
import { getData } from "../../../Services";
import { padding } from "@mui/system";
import ReportsIcon from "../../icons/reports";

const Sidebar = ({ isActive, setIsActive, sidebarRef }) => {
  const screenWidth = useScreenWidth();
  let accessData = useSelector((state) => state?.accessData?.accessData);
  // accessData = accessData && accessData.length > 0 ? accessData : undefined;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [swvalue, setSwValue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);
  const [cookies, setCookies] = useCookies(["un", "ibu", "mode", "iso"]);
  const [expandedMenu, setExpandedMenu] = useState(null);

  const handleExpandMenu = (menuIndex) => {
    setExpandedMenu((prev) => (prev === menuIndex ? null : menuIndex)); // Toggle current menu or close it
  };
  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getUserInfo = async () => {
    try {
      const res = await getData({
        endpoint: "business/getBusinessByID",
        token: cookies.t,
        params: {
          id: cookies.bid,
        },
      });
      setProfile(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  const isOwner = cookies.iso;

  const links = [
    ...((accessData &&
      (accessData?.["Dashboard"]?.["view"] ||
        accessData?.["Dashboard"]?.["delete"] ||
        accessData?.["Dashboard"]?.["export"] ||
        accessData?.["Dashboard"]?.["add/edit"])) ||
    isOwner
      ? [
          {
            to:
              cookies && cookies?.mode && atob(cookies?.mode) === "Business"
                ? "/dashboard/home"
                : cookies &&
                  cookies?.mode &&
                  (atob(cookies?.mode) === "Employee" ||
                    atob(cookies?.mode) === "Client")
                ? "/dashboard/ehome"
                : "/dashboard",
            label: "Dashboard",
            icon: <Dashboard />,
            alternatePath: "/dashboard",
          },
        ]
      : []),

    ...(((accessData &&
      (accessData?.["Client Users"]?.["view"] ||
        accessData?.["Client Users"]?.["delete"] ||
        accessData?.["Client Users"]?.["export"] ||
        accessData?.["Client Users"]?.["add/edit"] ||
        accessData?.["Employee Users"]?.["view"] ||
        accessData?.["Employee Users"]?.["delete"] ||
        accessData?.["Employee Users"]?.["export"] ||
        accessData?.["Employee Users"]?.["add/edit"])) ||
      isOwner) &&
    cookies.mode &&
    (atob(cookies.mode) === "Business" || swvalue === "Business")
      ? [
          {
            label: "Users",
            icon: <Broker />,
            alternatePath: "/dashboard/employees",
            subLinks: [
              ...((accessData &&
                (accessData?.["Client Users"]?.["view"] ||
                  accessData?.["Client Users"]?.["delete"] ||
                  accessData?.["Client Users"]?.["export"] ||
                  accessData?.["Client Users"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/clients",
                      label: "Client Users",
                      icon: <Dashboard />,
                      alternatePath: "/dashboard/clients",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Employee Users"]?.["view"] ||
                  accessData?.["Employee Users"]?.["delete"] ||
                  accessData?.["Employee Users"]?.["export"] ||
                  accessData?.["Employee Users"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/employees",
                      label: "Employee Users",
                      icon: <Dashboard />,
                      alternatePath: "/dashboard/employees",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(((accessData &&
      (accessData?.["Client Invoices"]?.["view"] ||
        accessData?.["Client Invoices"]?.["delete"] ||
        accessData?.["Client Invoices"]?.["export"] ||
        accessData?.["Client Invoices"]?.["add/edit"] ||
        accessData?.["Employee Invoices"]?.["view"] ||
        accessData?.["Employee Invoices"]?.["delete"] ||
        accessData?.["Employee Invoices"]?.["export"] ||
        accessData?.["Employee Invoices"]?.["add/edit"])) ||
      isOwner) &&
    cookies.mode &&
    (atob(cookies.mode) === "Business" || swvalue === "Business")
      ? [
          {
            label: "Invoices",
            icon: <Payments />,
            alternatePath: "/dashboard/employees",
            subLinks: [
              ...((accessData &&
                (accessData?.["Client Invoices"]?.["view"] ||
                  accessData?.["Client Invoices"]?.["delete"] ||
                  accessData?.["Client Invoices"]?.["export"] ||
                  accessData?.["Client Invoices"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/clientInvoice",
                      label: "Client Invoices",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Employee Invoices"]?.["view"] ||
                  accessData?.["Employee Invoices"]?.["delete"] ||
                  accessData?.["Employee Invoices"]?.["export"] ||
                  accessData?.["Employee Invoices"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/employeeInvoice",
                      label: "Employee Invoices",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(cookies.mode &&
    (atob(cookies.mode) === "Employee" || atob(cookies.mode) === "Client") &&
    (swvalue === "Employee" || swvalue === "Client")
      ? [
          {
            to: "/dashboard/bills",
            label: "Bills",
            icon: <Payments />,
            alternatePath: "/dashboard/bills",
          },
        ]
      : []),

    ...((accessData &&
      (accessData?.["Dashboard"]?.["view"] ||
        accessData?.["Dashboard"]?.["delete"] ||
        accessData?.["Dashboard"]?.["export"] ||
        accessData?.["Dashboard"]?.["add/edit"])) ||
    isOwner
      ? [
          {
            // to:
            //   cookies && cookies?.mode && atob(cookies?.mode) === "Business"
            //     ? "/dashboard/home"
            //     : cookies &&
            //       cookies?.mode &&
            //       (atob(cookies?.mode) === "Employee" ||
            //         atob(cookies?.mode) === "Client")
            //     ? "/dashboard/ehome"
            //     : "/dashboard",
            to:
              cookies &&
              cookies?.mode &&
              (atob(cookies?.mode) === "Employee" ||
                atob(cookies?.mode) === "Client")
                ? "/dashboard/ereports"
                : "/dashboard/reports",
            label: "Reports",
            icon: <ReportsIcon />,
            alternatePath: "/dashboard",
          },
        ]
      : []),

    ...(((accessData &&
      (accessData?.["Category Management"]?.["view"] ||
        accessData?.["Category Management"]?.["delete"] ||
        accessData?.["Category Management"]?.["export"] ||
        accessData?.["Category Management"]?.["add/edit"] ||
        accessData?.["Client Management"]?.["view"] ||
        accessData?.["Client Management"]?.["delete"] ||
        accessData?.["Client Management"]?.["export"] ||
        accessData?.["Client Management"]?.["add/edit"] ||
        accessData?.["User Management"]?.["view"] ||
        accessData?.["User Management"]?.["delete"] ||
        accessData?.["User Management"]?.["export"] ||
        accessData?.["User Management"]?.["add/edit"])) ||
      isOwner) &&
    cookies.mode &&
    (atob(cookies.mode) === "Business" || swvalue === "Business")
      ? [
          {
            label: "Settings",
            icon: <Configuration />,
            alternatePath: "/dashboard/setting",
            subLinks: [
              ...((accessData &&
                (accessData?.["User Management"]?.["view"] ||
                  accessData?.["User Management"]?.["delete"] ||
                  accessData?.["User Management"]?.["export"] ||
                  accessData?.["User Management"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/setting/user-management",
                      label: "User Management",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Client Management"]?.["view"] ||
                  accessData?.["Client Management"]?.["delete"] ||
                  accessData?.["Client Management"]?.["export"] ||
                  accessData?.["Client Management"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/clientManagement",
                      label: "Client Management",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Expense Categories"]?.["view"] ||
                  accessData?.["Expense Categories"]?.["delete"] ||
                  accessData?.["Expense Categories"]?.["export"] ||
                  accessData?.["Expense Categories"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/setting/category-management",
                      label: "Expense Categories",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  // const memoizedSetIsActive = useCallback((newIsActive) => {
  //  setIsActive (newIsActive);
  // }, []);

  // useEffect(() => {
  //   memoizedSetIsActive(screenWidth >= 1233);
  // }, [screenWidth, memoizedSetIsActive]);
  const handleSideBar = () => {
    // if (screenWidth < 1233) setIsActive(false);
  };
  useEffect(() => {
    if (cookies.mode) {
      setSwValue(atob(cookies.mode));
    }
  }, [cookies.mode]);
  return (
    <>
      <div className={`sidebar ${!isActive ? "closed" : ""}`} ref={sidebarRef}>
        <div
          className="header"
          style={{
            padding:
              profile?.logo && profile?.logo !== "null"
                ? "10px 15px"
                : "1.5rem 0 1.5rem 1.5rem",
          }}
        >
          {/* {isActive ? (
            // <img
            //   src={CompleteLogo}
            //   alt="Logo"
            //   className="open-header-img"
            //   onClick={() => navigate("/dashboard")}
            // />
            // <Logo1 />
            // <img src={Logo1} alt="Logo" />
            <CompanyLogo />
          ) : (
            // <> </>
            <ShortCompantLogo />
            // <img
            //   src={CompleteLogo}
            //   alt="Logo"
            //   className="closed-header-img"
            //   onClick={() => navigate("/dashboard")}
            // />
          )} */}

          {profile?.logo && profile?.logo !== "null" ? (
            <img
              src={profile?.logo}
              alt="Logo"
              className="open-header-img"
              onClick={() => navigate("/dashboard")}
            />
          ) : (
            <CompanyLogo />
          )}
        </div>
        <ul className="ul">
          {links.map((link, index) => (
            <CustomNavLink
              key={index}
              to={link.to}
              label={link.label}
              icon={link.icon}
              handleSideBar={handleSideBar}
              isSideBarActive={isActive}
              setIsSideBarActive={setIsActive}
              isExpanded={expandedMenu === index} // Pass down whether this submenu is expanded
              onExpand={() => handleExpandMenu(index)} // Pass the handler for expanding this submenu
            >
              {link.subLinks?.map((subLink, subIndex) => (
                <CustomNavLink
                  key={subIndex}
                  to={subLink.to}
                  label={subLink.label}
                  handleSideBar={handleSideBar}
                  isSideBarActive={isActive}
                />
              ))}
            </CustomNavLink>
          ))}
        </ul>

        {isActive && cookies?.ibu && (
          <MyDropdown
            data={
              cookies.mode &&
              (atob(cookies.mode) === "Employee" ||
                atob(cookies.mode) === "Business") &&
              cookies.eid !== undefined &&
              cookies.eid !== "undefined"
                ? switchingEmployee
                : switchingClient
            }
            selectedValue={swvalue}
            onChange={(option) => {
              setSwValue(option);
              setCookies("mode", btoa(option), {
                path: "/",
                expires: expitationTime(),
                // secure: true,
              });
            }}
            type={"Country"}
            classes="Dropdowm_switching_console"
            openUpwards={true}
          />
        )}

        <div
          className="profile mb-2"
          style={{ bottom: isActive && cookies?.ibu ? "70px" : "0" }}
          onClick={handleProfileMenu}
        >
          <div className="d-flex gap-2 align-items-center">
            <CircleAvatar
              color="#DEE3E7"
              iconColor="black"
              name={capitalizeLetter(decodeCookieValue(cookies.un))}
            />
            {isActive && (
              <div>
                <div
                  className="profile-span"
                  title={capitalizeLetter(decodeCookieValue(cookies.un))}
                >
                  {capitalizeLetter(decodeCookieValue(cookies.un))}
                </div>
              </div>
            )}
          </div>

          {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              minWidth: "175px",
              borderRadius: "6px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
              marginBottom: "40px",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "bottom",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "bottom",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(
                cookies.mode && atob(cookies.mode) === "Business"
                  ? "/dashboard/edit-profile"
                  : "/dashboard/emode/edit-profile"
              );
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span>Edit Profile</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        {isModalOpen && (
          <LogoutModal
            isActive={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};
export default Sidebar;
