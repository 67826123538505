/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { decodeCookieValue } from "../helpers/cookieHelper";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccessData } from "../store/slices/accessSlice";
import { type } from "@testing-library/user-event/dist/type";

const AuthGuard = (props) => {
  let accessData = useSelector((state) => state?.accessData?.accessData);
  accessData = accessData && accessData.length > 0 ? accessData : undefined;

  //   const routePermissions = useMemo(() => [
  //     { route: '/dashboard/business', permission: accessData?.Business?.is_view },
  //     { route: '/dashboard/business/add', permission: accessData?.Business?.is_view && accessData?.Business?.is_create},
  //     { route: `/dashboard/business/edit`, permission: accessData?.Business?.is_view && accessData?.Business?.is_edit},
  //     { route: '/dashboard/plans', permission: accessData?.['Subscription Plans']?.is_view },
  //     { route: '/dashboard/plans/add', permission: accessData?.['Subscription Plans']?.is_view && accessData?.['Subscription Plans']?.is_create},
  //     { route: '/dashboard/plans/edit', permission: accessData?.['Subscription Plans']?.is_view && accessData?.['Subscription Plans']?.is_edit},
  //     { route: '/dashboard/payments', permission: accessData?.Payments?.is_view },
  //     { route: '/dashboard/payments/add', permission: accessData?.Payments?.is_view && accessData?.Payments?.is_create},
  //     { route: '/dashboard/access-management', permission: accessData?.['Access Management']?.is_view },
  //     { route: '/dashboard/access-management/add', permission: accessData?.['Access Management']?.is_view && accessData?.['Access Management']?.is_create},
  //     { route: '/dashboard/configurations', permission: accessData?.Configurations?.is_view },
  //   ], [accessData]);

  const [cookies, setCookie] = useCookies([
    "role",
    "t",
    "mode",
    "ibu",
    "email",
  ]);
  const { children, label, value } = props;
  const auth = useAuthenticated();
  // const auth = true;
  const location = useLocation();
  const navigate = useNavigate();
  const decodedMode = decodeCookieValue(cookies.mode);
  const given_mode = props.mode;
  const dispatch = useDispatch();

  const [requestedLocation, setRequestedLocation] = useState();

  useEffect(() => {
    // const fetchData = async () => {
    //   if (cookies.role) {
    //     console.log("Entered boss", atob(cookies.role), cookies.t);
    //     await dispatch(
    //       fetchAccessData({ role: atob(cookies.role), token: cookies.t })
    //     ); // Dispatch the thunk action
    //   }
    // };
    const fetchData = async () => {
      if (cookies.role) {
        const result = await dispatch(
          fetchAccessData({
            role: atob(cookies.role),
            email: atob(cookies.email),
            token: cookies.t,
            cookies,
            setCookie,
            navigate,
          })
        );
      }
    };

    fetchData();
  }, [location.pathname]);

  // const fetchData = async () => {
  //   if (cookies.role) {
  //     console.log("Entered boss", atob(cookies.role), cookies.t);
  //     const result = await dispatch(
  //       fetchAccessData({
  //         role: atob(cookies.role),
  //         email: atob(cookies.email),
  //         token: cookies.t,
  //         setCookie,
  //       })
  //     );
  //     console.log("Result :", result);
  //   }
  // };

  // fetchData();

  //   const hasPermission = useMemo(() => {

  //     let currentRoute = routePermissions.find(rp => rp.route === location.pathname);

  //     if (!currentRoute) {
  //       currentRoute = routePermissions.find(rp => {
  //         const regex = new RegExp(`^${rp.route?.replace(/\/:.*$/, '/[^/]+')}$`);
  //         return regex.test(location.pathname);
  //       });
  //     }

  //     // If still no match, check for a prefix match
  //     if (!currentRoute) {
  //       currentRoute = routePermissions
  //         .filter(rp => location.pathname?.startsWith(rp.route))
  //         .sort((a, b) => b.route.length - a.route.length)[0];
  //     }

  //     return currentRoute ? currentRoute.permission : true;
  //   }, [location.pathname, routePermissions]);

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }

  if (props.type === "Dashboard") {
    if (cookies.ibu) {
      if (decodedMode === "Business") {
        return <Navigate to="/dashboard/home" replace />;
      } else {
        return <Navigate to="/dashboard/ehome" replace />;
      }
    } else {
      return <Navigate to="/dashboard/ehome" replace />;
    }
  }
  // if (decodedMode !== given_mode || props.type === "Dashboard") {
  //   if (cookies.ibu) {
  //     if (decodedMode === "Business") {
  //       return <Navigate to="/dashboard/home" replace />;
  //     } else {
  //       return <Navigate to="/dashboard/ehome" replace />;
  //     }
  //   }
  //   return <Navigate to="/dashboard/not-authorized" replace />;
  // }

  if (
    decodedMode === "Business"
      ? decodedMode !== given_mode
      : !given_mode?.includes(decodedMode)
  ) {
    if (decodedMode === "Business") {
      return <Navigate to="/dashboard/home" replace />;
    } else {
      return <Navigate to="/dashboard/ehome" replace />;
    }
    // return <Navigate to="/dashboard/not-authorized" replace />;
  }

  if (decodedMode === "Employee" || decodedMode === "Client") {
    return <EmployeeAuthGuard>{props.children}</EmployeeAuthGuard>;
  }

  if (decodedMode === given_mode && given_mode === "Business") {
    return <BusinessAuthGaurd>{props.children}</BusinessAuthGaurd>;
  }

  return <>{children}</>;

  // if (
  //   label && value
  //     ? accessData[label][value] === true
  //       ? false
  //       : accessData[label][value] === "undefined"
  //       ? true
  //       : false
  //     : false
  // ) {
  //   return <Navigate to="/dashboard/not-found" />;
  // }
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

const EmployeeAuthGuard = (props) => {
  // const [cookies] = useCookies(["va"]);

  // const is_admin = decodeCookieValue(cookies.va) === "true" ? true : false;

  // const routePermissions = useMemo(
  //   () => [
  //     { route: "/dashboard/employee/employees", permission: is_admin },
  //     { route: "/dashboard/employee/employees/add", permission: is_admin },
  //     { route: "/dashboard/employee/employees/edit", permission: is_admin },
  //   ],
  //   [cookies.va]
  // );

  const { children } = props;
  const auth = useAuthenticated();
  // const auth = true
  const location = useLocation();

  const [requestedLocation, setRequestedLocation] = useState();

  // const hasPermission = useMemo(() => {
  //   let currentRoute = routePermissions.find(
  //     (rp) => rp.route === location.pathname
  //   );

  //   if (!currentRoute) {
  //     currentRoute = routePermissions.find((rp) => {
  //       const regex = new RegExp(`^${rp.route?.replace(/\/:.*$/, "/[^/]+")}$`);
  //       return regex.test(location.pathname);
  //     });
  //   }

  //   // If still no match, check for a prefix match
  //   if (!currentRoute) {
  //     currentRoute = routePermissions
  //       .filter((rp) => location.pathname?.startsWith(rp.route))
  //       .sort((a, b) => b.route.length - a.route.length)[0];
  //   }

  //   return currentRoute ? currentRoute.permission : true;
  // }, [location.pathname, routePermissions]);

  // if (!hasPermission) {
  //   return <Navigate to="/404" />;
  // }

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }
  return <>{children}</>;
};

const BusinessAuthGaurd = (props) => {
  const { allowedRole, children } = props;

  const isAuthenticated = useAuthenticated();

  // const [cookies] = useCookies(["br"]);
  // const decodeRole = decodeCookieValue(cookies.br);

  // if (isAuthenticated && allowedRole?.includes(decodeRole.toLowerCase())) {
  //   return children;
  // }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/auth/login" replace />;
};

export default AuthGuard;
