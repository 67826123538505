import React from "react";
import ReactECharts from "echarts-for-react";
import moment from "moment";

const VisitorChart = ({ data }) => {
  // Extract dates and visitor counts
  const dates = data?.map((item) => moment(item?.date).format("DD/MM/YYYY"));
  const visitorCounts = data?.map((item) => item?.count);

  const options = {
    animation: true,
    animationDuration: 3000,
    animationEasing: "cubicOut",
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: "category",
      data: dates,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: visitorCounts,
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#3b424a",
        },
        lineStyle: {
          color: "#3b424a",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  return <ReactECharts option={options} />;
};

export default VisitorChart;
