import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import Pagination from "../../../../atoms/Pagination";
// import { setLoading } from "../../../../../store/slices/loaderSlice";
import Modal from "../../../../atoms/modals/Modal";
import DeleteModal from "../../../../atoms/modals/DeleteModal";
import Form from "./Form";
import { getData } from "../../../../../Services";
// import { render } from "@testing-library/react";
import Edit from "../../../../icons/EditBlue";
import Delete from "../../../../icons/Delete";
import { useDelete } from "../../../../../hooks/useDelete";
// import NoContentCard from "../../../../atoms/NoContentCard";
// import NoContentIcon from "../../../../icons/Counterparty";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import { Heading } from "../../../../atoms/Heading";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import moment from "moment";
// import View from "../../../../icons/View";
// import Signer from "../../../../icons/Signer";
import UserIcon from "../../../../icons/user.icon";
import Counterparty from "../../../../icons/Counterparty";
import NoContentCard from "../../../../atoms/NoContentCard";
import { useSelector } from "react-redux";

const RoleGroupMangement = () => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const screenWidth = useScreenWidth();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [editData, setEditData] = useState();
  const [detailsData, setDetailsData] = useState({
    isActive: false,
    data: null,
  });
  const [clientsData, setClientsData] = useState({
    isActive: false,
    data: null,
  });
  const [selectedActivityIds, setSelectedActivityIds] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const menu =
    selectedRow?.users?.length > 0
      ? [
          {
            icon: <Counterparty fill="black" />,
            label: "View Clients",
            onClick: () => {
              setClientsData({
                isActive: true,
                data: selectedRow,
              });
              setAnchorEl(null);
            },
          },
          {
            icon: <UserIcon height="16" width="16" />,
            label: "View Users",
            onClick: () => {
              setDetailsData({
                isActive: true,
                data: selectedRow,
              });
              setAnchorEl(null);
            },
          },
          {
            icon: <Edit height="20" width="20" />,
            label: "Edit",
            onClick: () => {
              setSelectedActivityIds([]);
              setEditData(selectedRow);
              setAnchorEl(null);
            },
          },
          {
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalState({
                isDeleteModalOpen: true,
                name: selectedRow.name,
                modalId: selectedRow._id,
              });
              setAnchorEl(null);
            },
          },
        ]
      : [
          {
            icon: <Counterparty fill="black" />,
            label: "View Clients",
            onClick: () => {
              setClientsData({
                isActive: true,
                data: selectedRow,
              });
              setAnchorEl(null);
            },
          },
          {
            icon: <Edit height="20" width="20" />,
            label: "Edit",
            onClick: () => {
              setSelectedActivityIds([]);
              setEditData(selectedRow);
              setAnchorEl(null);
            },
          },
          {
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalState({
                isDeleteModalOpen: true,
                name: selectedRow.name,
                modalId: selectedRow._id,
              });
              setAnchorEl(null);
            },
          },
        ];

  const getUserData = useCallback(async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "rolegroupBusiness",
        params: {
          search: searchedValue,
          page: currentPage,
          limit: itemsPerPage,
          business_id: cookies.bid,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    setLoader(false);
    getUserData();
  }, [getUserData]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Group Name",
      ...(screenWidth < 900 ? { width: 180 } : { flex: 1 }),
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{`${params.row.name} ${
              params.row.users.length > 0 ? `(${params.row.users.length})` : ""
            }`}</span>
          </>
        );
      },
    },
    {
      field: "client_name",
      headerName: "Client Names",
      ...(screenWidth < 900 ? { width: 180 } : { flex: 1 }),
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div title={params?.row?.roleInfo?.map((item) => item?.name)}>
              {params?.row?.roleInfo?.length > 1 &&
                `${params?.row?.roleInfo?.[0]?.name} ${
                  params?.row?.roleInfo?.length > 1
                    ? `..+${params?.row?.roleInfo?.length - 1}`
                    : ""
                }`}
            </div>
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      ...(screenWidth < 900 ? { width: 80 } : { flex: 1 }),
      renderCell: (params) => {
        return (
          // <div className="table-icons-container d-flex ">
          //   <div
          //     onClick={() => setEditData(params.row)}
          //     style={{ marginTop: '1px' }}
          //   >
          //     <Edit />
          //   </div>
          //   <div onClick={() => handleDeleteModal(params.row)}>
          //     <Delete />
          //   </div>
          // </div>

          <>
            <>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>

              <CustomMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedRow === params.row}
                onClose={handleClose}
                items={getMenuList()}
                // items={menu}
              />
            </>
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (
      (roleData &&
        roleData?.["add/edit"] &&
        roleData?.view &&
        roleData?.delete) ||
      isOwner
    ) {
      return menu;
    } else if (roleData && roleData?.["add/edit"] && roleData?.view) {
      return selectedRow?.users?.length > 0
        ? [menu[0], menu[1], menu[2]]
        : [menu[0], menu[1]];
    } else if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return selectedRow?.users?.length > 0
        ? [menu[2], menu[3]]
        : [menu[1], menu[2]];
    } else if (roleData && roleData?.view && roleData?.delete) {
      return selectedRow?.users?.length > 0
        ? [menu[0], menu[1], menu[3]]
        : [menu[0], menu[2]];
    } else if (roleData && roleData?.view) {
      return selectedRow?.users?.length > 0 ? [menu[0], menu[1]] : [menu[0]];
    } else if (roleData && roleData?.delete) {
      return selectedRow?.users?.length > 0 ? [menu[3]] : [menu[2]];
    } else if (roleData && roleData?.["add/edit"]) {
      return selectedRow?.users?.length > 0 ? [menu[2]] : [menu[1]];
    } else return [];
  };
  const CustomNoRowsOverlay = React.memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!searchedValue ? (
          <NoContentCard title="No Record Found" type="Counterparty" />
        ) : (
          <NoContentCard
            title="No Role Group Added Yet!"
            tag="Add User"
            type="Counterparty"
            // {...(roleData && roleData?.["add/edit"]
            //   ? {
            //       handleClick: () => openModal("Add User"),
            //       subtitle: "Click on  Add User button to add a user",
            //     }
            //   : {})}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // add , edit , delete
  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };

  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      modalId: data._id,
      modalData: data,
      isModalOpen: true,
      title: "Edit User",
    }));
  };

  const deleteUser = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data._id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteUser("rolegroupBusiness/delete", "RoleGroup", {
        id: modalState.modalId,
      });
      getUserData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        {/* <div className="searchButton">
          <CustomButton
            type="btn-primary"
            text="Add User"
            handleClick={() => openModal('Add User')}
          />
        </div> */}
      </div>
      {/* {roleData && roleData?.["add/edit"] && ( */}
      <>
        <Heading className="h5 mt-3 lh-1">
          {editData?._id ? "Edit" : "Add"} Client Group
        </Heading>

        <Form
          id={editData?._id}
          initialValues={editData}
          refetchData={getUserData}
          setEditData={setEditData}
          selectedActivityIds={selectedActivityIds}
          setSelectedActivityIds={setSelectedActivityIds}
        />
      </>
      {/* )} */}

      <div className="headingSearchWrap mt-4">
        <Heading className="HeadingStyle mb-0">Client Group List</Heading>
        <Search
          onSearchEmit={(value) => {
            setSearchedValue(value);
            if (value.length > 2) {
              if (currentPage !== 1) setCurrentPage(1);
            }
          }}
        />
      </div>

      <CustomDatagrid
        getRowId={(row) => row._id}
        rows={tableData?.data?.[0]?.roleData || []}
        columns={columns}
        // tHeight="50vh"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {tableData?.data?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.data?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {/* {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: 'visible',
            cssText: 'overflow: visible !important',
          }}
          width="400px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === 'Edit User' ? modalState.modalData : null
            }
            refetchData={getUserData}
          />
        </Modal>
      )} */}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Role Group"
          onClick={handleDeletion}
        />
      )}
      {detailsData.isActive && (
        <Modal
          isActive={detailsData.isActive}
          onClose={() =>
            setDetailsData({
              isActive: false,
              data: null,
            })
          }
          title={`Members of ${detailsData?.data?.name}`}
          width="40vw"
        >
          <div className="w-100">
            {detailsData?.data?.users?.map((item, index) => (
              <div className="d-flex mb-1">
                <div className="w-50">{item?.name}</div>
                <div className="w-50">{item?.email}</div>
                {/* <div>{item?.role?.role_name}</div> */}
              </div>
            ))}
          </div>
        </Modal>
      )}
      {clientsData.isActive && (
        <Modal
          isActive={clientsData.isActive}
          onClose={() =>
            setClientsData({
              isActive: false,
              data: null,
            })
          }
          title={`Client Users of ${clientsData?.data?.name}`}
          width="30vw"
        >
          <div className="w-100">
            {clientsData?.data?.roleInfo &&
            clientsData?.data?.roleInfo.length > 0 ? (
              clientsData?.data?.roleInfo?.map((item, index) => (
                <div className="d-flex mb-1">
                  <div className="w-50">{`${index + 1}. ${item?.name}`}</div>
                </div>
              ))
            ) : (
              <NoContentCard title="No Client Users Are Added!" />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default RoleGroupMangement;
