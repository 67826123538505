import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { getData } from "../../../../../Services";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import moment from "moment";
import NoContentCard from "../../../../atoms/NoContentCard";
import CategoryCardList from "./Categorycard";
import useScreenWidth from "../../../../../hooks/useScreenwidth";

const BillsTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["t", "bid", "eid", "mode", "cid"]);
  const getMode = cookies && cookies?.mode ? atob(cookies.mode) : "";
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchData, setSearchData] = useState();
  const [loader, setLoader] = useState(true);

  const GetemployeeData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}`,
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          business_id: cookies.bid,
          [getMode !== "Client" ? "employee_id" : "client_id"]:
            getMode !== "Client" ? cookies.eid : cookies.cid,
        },
      });
      setEmployeeData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetemployeeData();
  }, [itemsPerPage, currentPage, searchData]);

  const columns = [
    {
      field: "name",
      headerName: "Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.categoryData && (
              <div>{params?.row?.categoryData?.category}</div>
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Sub Category",
      flex: 1,
      sortable: true,
    },
    {
      field: "currecy",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return <>{params?.row?.currency && <div>{params.row.currency}</div>}</>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status === "New/Pending" ? (
              <span className="text-warning fw-bold">Pending</span>
            ) : params?.row.status === "Booked" ? (
              <span className="text-success fw-bold">Closed</span>
            ) : params?.row.status === "Rejected" ? (
              <span className="text-error fw-bold">Rejected</span>
            ) : (
              <span className="text-dark fw-bold">Raised Query</span>
            )}
          </>
        );
      },
    },
  ];

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        <>
          <NoContentCard
            title="No Bills Found"
            tag="Create Bill"
            type="Pricing"
          />
        </>
      </>
    );
  });

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {screenWidth > 900 ? (
        <CustomDatagrid
          rows={employeeData?.[0]?.roleData || []}
          getRowId={(row) => row?._id}
          columns={columns}
          loader={loader}
          height={() => 50}
          hoverColor="ffffff"
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          onCellClick={(data) => {
            if (data?.field !== "actions" && data?.field !== "__check__") {
              navigate(
                `/dashboard/bills/view/${data?.id}`
                //     {
                //   state: filters,
                // }
              );
            }
          }}
        />
      ) : employeeData && employeeData?.[0]?.roleData?.length > 0 ? (
        <CategoryCardList rows={employeeData?.[0]?.roleData || []} />
      ) : (
        <CustomNoRowsOverlay />
      )}
      {employeeData?.[0]?.pageDetails?.count > 1 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={100}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
          options={["5", "10", "15"]}
        />
      )}
    </>
  );
};
export default BillsTable;
