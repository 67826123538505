import React from "react";
import CategoryCard from "./cardList";

const CategoryCardList = ({
  rows,
  handleStatusMenu,
  anchorEl,
  selectedRow,
  handleClose,
  menu,
}) => {
  return (
    <div className="card-list">
      {rows.map((row, index) => (
        <CategoryCard
          key={row?._id || index}
          row={row}
          handleStatusMenu={handleStatusMenu}
          anchorEl={anchorEl}
          selectedRow={selectedRow}
          handleClose={handleClose}
          menu={menu}
        />
      ))}
    </div>
  );
};

export default CategoryCardList;
