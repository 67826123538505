import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData, statusData2 } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import { useSelector } from "react-redux";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";

const UserForm = ({ onClose, initialValues, id, refetchData, setEditData }) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [rolesData, setRolesData] = useState(false);
  const [roleGroupData, setRoleGroupData] = useState();
  const [emailError, setEmailError] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(true);

  const initialData = {
    name: initialValues?.name || "",
    email: initialValues?.email || "",
    role_id: initialValues?.role_id || "",
    role_group_id: initialValues?.role_group_id || null,
    business_id: cookies?.bid || "",
    status: initialValues?.status || "Pending",
  };

  const getRolesData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "/roleBusiness/getRolesList",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
        },
      });

      if (res) {
        setRolesData(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  const getRolesGroupData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "/rolegroupBusiness/getRolesList",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
        },
      });

      if (res) {
        setRoleGroupData(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getRolesData();
    getRolesGroupData();
  }, [getRolesData]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
    role_id: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const res = await postData({
      endpoint: "businessAuth/createUser",
      token: cookies.t,
      data: {
        ...values,
        email: values.email.toLowerCase(),
        name: capitalizeLetter(values.name),
      },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("User Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values, { resetForm }) => {
    try {
      const res = await patchData({
        endpoint: "businessAuth/updateUser",
        token: cookies.t,
        params: {
          id: initialValues?._id,
        },
        data: {
          ...values,
          email: values.email.toLowerCase(),
          name: capitalizeLetter(values.name),
        },
      });

      if (res.statusCode) {
        toast.success("User Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "businessAuth/checkEmail",
      token: cookies.t,
      params: { email: newVal, business_id: cookies.bid },
    });
    if (response.status && response.data) {
      response.message[0] === "U"
        ? setEmailError(
            "User Is Mapped with Another Business,Use Different Email"
          )
        : setEmailError("Email Already Exists");
      setIsEmailChecked(false);
    } else {
      setEmailError("");
      setIsEmailChecked(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={initialValues ? handleEdit : handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => (
        <Form autoComplete="off">
          <div className="row mt-3">
            <div className="col-3">
              <MyInput
                type="text"
                id="name"
                name="name"
                totalWidth={true}
                customholder="Enter Name"
                required
              />
            </div>

            <div className="col-3">
              <MyInput
                type="email"
                id="email"
                name="email"
                totalWidth={true}
                customholder="Enter Email"
                customChange={(option) => {
                  if (initialValues?.email !== option) {
                    checkEmail(option);
                  }
                }}
                blurError={emailError}
                required
                allowSpecial
              />
            </div>

            <div className="col-3">
              <MyDropdown
                data={roleGroupData}
                placeholder="Select Client Group"
                onChange={(option) => {
                  setFieldValue("role_group_id", option);
                }}
                selectedValue={values?.role_group_id}
                isError={errors.role_group_id}
                touched={touched.role_group_id}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("role_group_id", true);
                }}
                errors={errors.role_group_id}
              />
            </div>
            <div className="col-3">
              <MyDropdown
                data={rolesData}
                placeholder="Select Role"
                onChange={(option) => {
                  setFieldValue("role_id", option);
                }}
                selectedValue={values?.role_id}
                isError={errors.role_id}
                touched={touched.role_id}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("role_id", true);
                }}
                errors={errors.role_id}
                required
              />
            </div>

            {initialValues && values.status !== "Pending" && (
              <div className="col-3 mt-3">
                <MyDropdown
                  data={statusData2}
                  placeholder="Select Status"
                  onChange={(option) => {
                    setFieldValue("status", option);
                  }}
                  selectedValue={values?.status}
                />
              </div>
            )}
            {((roleData && roleData?.["add/edit"]) || isOwner) && (
              <div className={`col-3 mt-3`}>
                {initialValues ? (
                  <button
                    type="submit"
                    className="btn-smlg btn-primary w-50"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      (initialValues?.email !== values.email
                        ? emailError
                        : false) ||
                      (initialValues?.email !== values.email
                        ? isEmailChecked
                        : false)
                    }
                  >
                    Update
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                ) : (
                  <div className="d-flex gap-4 ">
                    <button
                      type="button"
                      className="btn-smlg btn-outline-primary w-25 flex-1"
                      disabled={isSubmitting}
                      onClick={() => resetForm()}
                    >
                      Reset
                    </button>

                    <button
                      type="submit"
                      className="btn-smlg btn-primary w-25 flex-1"
                      disabled={isSubmitting || emailError || isEmailChecked}
                    >
                      Add
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
