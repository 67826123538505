import { createSlice } from "@reduxjs/toolkit";
import { getData } from "../../Services";
import { useCookies } from "react-cookie";
import { expitationTime } from "../../helpers/expirationTime";

export const accessSlice = createSlice({
  name: "accessData",
  initialState: {
    accessData: {},
  },
  reducers: {
    setAccessData: (state, action) => {
      state.accessData = action.payload;
    },
  },
});

export const { setAccessData } = accessSlice.actions;
export const stateSelector = (state) => state?.accessSlice?.accessData;

export const fetchAccessData =
  ({ role, email, token, cookies, setCookie, navigate }) =>
  async (dispatch) => {
    try {
      const res = await getData({
        endpoint: "roleBusiness/getRoleByID2",
        params: { id: role, email: email },
        token: token,
      });

      const data = res.data.role_access;
      let refreshData = cookies?.ibu !== res.data.is_business_user;
      setCookie("ibu", res?.data?.is_business_user, {
        path: "/",
        expires: expitationTime(),
        // secure: true,
      });
      setCookie("eid", res?.data?.employee_id, {
        path: "/",
        expires: expitationTime(),
        // secure: true,
      });
      setCookie("cid", res?.data?.client_id, {
        path: "/",
        expires: expitationTime(),
        // secure: true,
      });

      const transformedData = data.reduce((acc, obj) => {
        const { module, ...permissions } = obj;
        acc[module] = permissions;
        return acc;
      }, {});

      dispatch(setAccessData(transformedData));
      if (
        !res?.data?.is_business_user &&
        atob(cookies.mode) !== res?.data?.mode
      ) {
        setCookie("mode", btoa(res?.data?.mode), {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
        res?.data?.mode === "Business"
          ? navigate("/dashboard/home")
          : navigate("/dashboard/ehome");
      }
      if (refreshData) {
        window.location.reload();
      }
      return res;
    } catch (error) {}
  };
export default accessSlice.reducer;
