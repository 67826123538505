import React from "react";
import moment from "moment";
import "../Bill/Card.css";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import CustomMenu from "../../../molecules/CustomMenu";

const ReportCard = ({
  row,
  handleStatusMenu,
  menu,
  anchorEl,
  selectedRow,
  handleClose,
}) => {
  return (
    <div className="card-container">
      {/* Top-right actions menu */}
      <div className="card-header">
        <span
          type="button"
          onClick={(event) => handleStatusMenu(event, row)}
          className="card-action-icon"
        >
          <HorizontalMenu />
        </span>
      </div>

      {/* Card content */}
      <div className="card-body">
        <div className="card-item">
          <span className="card-label">Report Type:</span>
          <span className="card-value">{`${row?.type || "N/A"}`}</span>
        </div>
        <div className="card-item">
          <span className="card-label">Generated On:</span>
          <span className="card-value">
            {moment(row?.createdAt).format("YYYY-MM-DD hh:mm A")}
          </span>
        </div>
      </div>

      {/* Custom Menu */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && selectedRow === row}
        onClose={handleClose}
        items={menu}
      />
    </div>
  );
};

const ReportCardList = ({
  rows,
  handleStatusMenu,
  menu,
  anchorEl,
  selectedRow,
  handleClose,
}) => {
  return (
    <div className="card-list">
      {rows.map((row, index) => (
        <ReportCard
          key={row?._id || index}
          row={row}
          handleStatusMenu={handleStatusMenu}
          anchorEl={anchorEl}
          selectedRow={selectedRow}
          handleClose={handleClose}
          menu={menu}
        />
      ))}
    </div>
  );
};

export default ReportCardList;
