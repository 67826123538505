import React from "react";
import ReactECharts from "echarts-for-react";
import { convertAndAddCurrencies } from "../helper/currency";
import { fontSize, height, width } from "@mui/system";

const PieChart = ({ data, type, unit }) => {
  let chartData;

  if (type === "Client") {
    chartData = data?.data?.map((item) => ({
      value: item.count,
      name: item.client_name,
    }));
  } else {
    chartData = data?.data?.map((item) => ({
      value: item.count,
      name: item.client_name,
    }));
  }
  let dynamicHeight = 280;
  if (chartData?.length) {
    dynamicHeight = Math.max(280, 280 + chartData?.length * 5);
  }

  // Chart options
  const chartOptions = {
    tooltip: {
      trigger: "item",
      formatter: `<strong>{b}:</strong> ${unit}{c} ({d}%)`,
      textStyle: {
        color: "#333",
        fontSize: "10px",
      },
    },
    color: [
      "#1E90FF", // Dodger blue
      "#4682B4", // Steel blue
      "#2F4F4F", // Dark slate gray
      "#708090", // Slate gray
      "#000000", // Black
    ],
    legend: {
      orient: "horizontal",
      bottom: 10,
      // type: "auto",
      itemWidth: 10, // Add this to make legend symbols smaller
      itemHeight: 10, // Add this to make legend symbols smaller

      textStyle: {
        color: "#333",
        fontSize: "10px", // Decrease font size of legend
      },
      padding: [0, 0, 0, 0],
    },
    grid: {
      // containLabel: true,
      top: "20%",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        bottom: "12%",
        // top: "0",
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        minHeight: `${dynamicHeight}px`,
      }}
    >
      <ReactECharts
        option={chartOptions}
        style={{ height: "100%", minHeight: `${dynamicHeight}px` }}
        // style={{ height: 'calc(100vw * 0.25)' }} // 2:1 aspect ratio
        opts={{ renderer: "canvas" }}
      />
    </div>
  );
};

export default PieChart;
