import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./styles.module.scss";

import LogoutModal from "../../atoms/modals/LogoutModal";
import { decodeCookieValue } from "../../../helpers/cookieHelper";

import { useCookies } from "react-cookie";

import useScreenWidth from "../../../hooks/useScreenwidth";
import Broker from "../../icons/Broker";
import Payments from "../../icons/Payments";
import ReportsIcon from "../../icons/reports";
import Dashboard from "../../icons/dashboard";
import { getData } from "../../../Services";
import CompanyLogo from "../../icons/companyLogo";

const filterRoutes = (routes, role) => {
  if (role === "Viewer") {
    return Object.fromEntries(
      Object.entries(routes).filter(
        ([key]) =>
          key !== "configurations" && key !== "logs" && key !== "invites/add"
      )
    );
  }
  return routes;
};

const Navbar = ({ mode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies([
    "bun",
    "profile",
    "both",
    "role",
    "va",
    "br",
    "acid",
  ]);
  const [isActive, setIsActive] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const hamburger = document.getElementById("hamburger_icon");
      const itemsContainer = document.getElementById("nav_items_container");

      if (
        hamburger &&
        !hamburger.contains(event.target) &&
        itemsContainer &&
        !itemsContainer.contains(event.target)
      ) {
        setIsActive(false);
      }
    };

    const handleScroll = () => {
      if (screenWidth < 1230) setIsActive(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  const getUserInfo = async () => {
    try {
      const res = await getData({
        endpoint: "business/getBusinessByID",
        token: cookies.t,
        params: {
          id: cookies.bid,
        },
      });
      setProfile(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path) ? styles.active_nav : "";
  };

  const employeeOldRoutes = {
    ehome: {
      name: "Dashboard",
      icon: <Dashboard />,
    },
    Bills: {
      name: "Bills",
      icon: <Payments />,
    },
    ereports: {
      name: "Reports",
      icon: <ReportsIcon />,
    },
  };

  const decodedValue = decodeCookieValue(cookies.va);

  let routeKeys = Object.keys(employeeOldRoutes);

  if (decodedValue === "true") {
    routeKeys = [...routeKeys.slice(0, 1), "employees", ...routeKeys.slice(1)];
  }

  const employeeRoutes = routeKeys.reduce((acc, key) => {
    if (key === "employees") {
      acc[key] = {
        name: "Employees",
        icon: <Broker />,
      };
    } else {
      acc[key] = employeeOldRoutes[key];
    }
    return acc;
  }, {});

  return (
    <div className={styles.navbar_container}>
      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <nav className={styles.navbar}>
        {/* Logo */}

        <div className="d-flex gap-1 align-items-center">
          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            id="hamburger_icon"
            onClick={toggleActiveClass}
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </div>

          <div
            className={styles.icon}
            onClick={() => navigate("/dashboard/ehome")}
          >
            {profile?.logo && profile?.logo !== "null" ? (
              <img
                src={profile?.logo}
                alt="Logo"
                className="open-header-img"
                onClick={() => navigate("/dashboard")}
              />
            ) : (
              <CompanyLogo />
            )}
          </div>
        </div>

        <ul
          className={` ${styles.navMenu} ${isActive ? styles.active : ""}`}
          id="nav_items_container"
        >
          <>
            {Object.entries(employeeRoutes).map(([path, { name, icon }]) => (
              <li key={path} onClick={removeActive}>
                <NavLink
                  to={path}
                  className={`${styles.navLink} ${isLinkActive(
                    `/dashboard/${path}`
                  )}`}
                >
                  {icon}
                  {name}
                </NavLink>
              </li>
            ))}
          </>
        </ul>

        <div className={styles.left_side}>
          <div onClick={handleMenuOpen(setProfileAnchorEl)}>
            <CircleAvatar name={decodeCookieValue(cookies.un)} />
          </div>
        </div>

        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={handleMenuClose(setProfileAnchorEl)}
          PaperProps={{
            style: {
              width: "200px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(`/dashboard/emode/edit-profile`);
              setProfileAnchorEl(null);
            }}
          >
            Edit Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setProfileAnchorEl(null);
            }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>
      </nav>
    </div>
  );
};

export default Navbar;
