import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@mantine/core/styles.css';
import { CookiesProvider } from "react-cookie";
import Errorboundary from "./Services/errorBoundarry";
import store from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
    <BrowserRouter>
    <Provider store={store}>
    <Errorboundary fallback="Something Went Wrong">
      <App />
      </Errorboundary>
      </Provider>
    </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);
