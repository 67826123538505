const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={20}
      height={20}
      color="#3b424a"
    >
      <path
        d="M24,12c0,6.617-5.383,12-12,12-1.167,0-2.32-.167-3.428-.497l.57-1.917c.923,.275,1.885,.414,2.858,.414,5.514,0,10-4.486,10-10S17.514,2,12,2c-.973,0-1.935,.139-2.858,.414l-.57-1.917c1.108-.33,2.262-.497,3.428-.497,6.617,0,12,5.383,12,12Zm-11.998,6c.567,0,1.132-.215,1.561-.643l3.727-3.642-1.398-1.431-2.892,2.827V6h-2V15.111l-2.891-2.826-1.398,1.431,3.718,3.634c.434,.434,1.004,.651,1.574,.651Zm-7.593,.511l-1.518,1.303c.748,.87,1.613,1.628,2.572,2.252l1.091-1.677c-.8-.52-1.521-1.152-2.145-1.878ZM.119,13.697c.16,1.129,.48,2.228,.952,3.266l1.82-.827c-.393-.864-.659-1.779-.792-2.719l-1.98,.28ZM.119,10.303l1.98,.28c.133-.94,.399-1.854,.792-2.719l-1.82-.827c-.472,1.038-.792,2.137-.952,3.266ZM2.902,4.175l1.516,1.305c.622-.722,1.34-1.35,2.137-1.868l-1.091-1.677c-.955,.621-1.816,1.375-2.562,2.24Z"
        stroke="#3b424a"
        fill="#3b424a"
      />
    </svg>
  );
};

export default DownloadIcon;
